import React from 'react'

import './styles.scss'

const Awards = () => {
	return (
		<div className="awards">
			<img
				src="/images/sale/apple-winner.png"
				alt="Apple. Best of apps winner."
				className="awards__apple-winner"
			/>
			<h2 className="awards__title">
				100M+ Widgetbox users all over the world
			</h2>
			<img
				src="/images/sale/awards.png"
				alt="Awards."
				className="awards__image"
			/>
		</div>
	)
}

export { Awards }
