import React, { useEffect, useState } from 'react'
import { scroller } from 'react-scroll'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import {
	getIsStoppedOfferTimer,
	getOfferTime,
	getUserId,
	setOfferTime,
	getSaleScreenTag,
} from '../../../../redux/slices/userSlice'
import { ampEvent } from '../../../../utils/amplitude'
import { paramsToQuery } from '../../../../utils/paramsToQuery'
import BurgerMenu from './component/BurgerMenu'
import Header from './component/Header'
import { getSaleScreenABTestValue } from '../../../../redux/slices/config'

// const HEADER_WIDTH = 69

const ONE_SECOND_TIMEOUT = 1000
const SECONDS_IN_MINUTE = 60
const SECONDS_FOR_UP = 10
const TIMER_SHOW_OFFSET_Y = 150

const HeaderProvider = ({ children }) => {
	const history = useHistory()
	const { pathname } = useLocation()
	const dispatch = useDispatch()
	const user_id = useSelector(getUserId)
	const timerValue = useSelector(getOfferTime)
	const isStoppedOfferTimer = useSelector(getIsStoppedOfferTimer)
	const [isShowTimer, setIsShowTimer] = useState(false)
	const saleScreenTag = useSelector(getSaleScreenTag)
	const saleScreenABTestValue = useSelector(getSaleScreenABTestValue)
	const [isOpenBurgerMenu, setIsOpenBurgerMenu] = useState(false)

	const backButtonAllowedPathnames = [
		'/faq',
		'/privacy-policy',
		'/account',
		'/terms-of-use',
		'/subscription-terms',
		'/money-back-policy',
		'/unsubscribe/reason',
		'/unsubscribe/offer',
	]

	const timerAllowedPathnames = ['/sale33']

	const fromDexToTime = time => {
		if (time > 0) {
			let minutes = parseInt(time / SECONDS_IN_MINUTE, SECONDS_FOR_UP)
			let seconds = parseInt(time % SECONDS_IN_MINUTE, SECONDS_FOR_UP)
			minutes = minutes < SECONDS_FOR_UP ? '0' + minutes : minutes
			seconds = seconds < SECONDS_FOR_UP ? '0' + seconds : seconds
			return minutes + ':' + seconds
		} else {
			return false
		}
	}

	const isTimerAllowed = timerAllowedPathnames.some(item =>
		pathname.includes(item),
	)

	const timerShowOnScrollHandler = () => {
		if (window.scrollY >= TIMER_SHOW_OFFSET_Y) {
			setIsShowTimer(true)
		} else {
			setIsShowTimer(false)
		}
	}

	const toggleBurgerMenu = () => setIsOpenBurgerMenu(!isOpenBurgerMenu)

	const handleCloseBurgerMenu = (pathname = '') => {
		setIsOpenBurgerMenu(false)

		if (pathname === '/account') {
			setTimeout(() => {
				ampEvent('authorization_email')
			}, 0)
		}
	}

	const isShowBackButton =
		!isOpenBurgerMenu &&
		(backButtonAllowedPathnames.includes(pathname) ||
			(pathname.includes('/questions') && pathname !== '/questions/1'))
	const handlePrevButtonClick = () => {
		if (pathname.includes('/question') && pathname !== '/questions/1') {
			const lastDashIndex = pathname.lastIndexOf('/')
			const questionId = pathname.slice(lastDashIndex + 1)
			return history.push({
				pathname: `/questions/${+questionId - 1}`,
				search: paramsToQuery({ user_id }),
			})
		}

		return history.goBack()
	}

	// useEffect(() => {
	// 	window.addEventListener('scroll', changeNav)
	// }, [])

	useEffect(() => {
		if (isOpenBurgerMenu) {
			document.body.style.overflow = 'hidden'
			return
		}
		document.body.style.overflow = 'visible'
	}, [isOpenBurgerMenu])

	const handleScrollToPrice = () => {
		scroller.scrollTo('price', {
			duration: 1500,
			spy: true,
			exact: true,
			smooth: true,
			offset: -100,
		})
	}

	useEffect(() => {
		window.addEventListener('scroll', timerShowOnScrollHandler)
	}, [])

	useEffect(() => {
		if (
			timerValue >= 0 &&
			pathname.includes('/sale33') &&
			!isStoppedOfferTimer &&
			(saleScreenTag === 7 || saleScreenABTestValue === 'original')
		) {
			setTimeout(() => {
				dispatch(setOfferTime({ offerTime: timerValue - 1 }))
			}, ONE_SECOND_TIMEOUT)
		}
	}, [timerValue, pathname, isStoppedOfferTimer, saleScreenTag])

	return (
		<>
			{saleScreenTag === 7 && pathname === '/sale33' ? (
				''
			) : (
				<>
					<Header
						onClickBackHandler={handlePrevButtonClick}
						isShowBackButton={isShowBackButton}
						isOpenBurgerMenu={isOpenBurgerMenu}
						toggleBurgerMenu={toggleBurgerMenu}
						isShowTimer={isTimerAllowed && isShowTimer}
						timerValue={fromDexToTime(timerValue)}
						handleScrollToPrice={handleScrollToPrice}
					/>
					<BurgerMenu
						isOpen={isOpenBurgerMenu}
						handleCloseBurgerMenu={handleCloseBurgerMenu}
					/>
				</>
			)}
			{children}
		</>
	)
}

export default HeaderProvider
