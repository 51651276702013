import React from 'react'

import './styles.scss'

const StatsCard = ({ image, title }) => {
	return (
		<div className="stats-card">
			<h2 className="stats-card__title">{title}</h2>
			<img
				src={`/images/sale/${image}.png`}
				alt="stats"
				className="stats-card__image"
			/>
		</div>
	)
}

export { StatsCard }
