import { combineReducers } from 'redux'

import questionsReducer from './slices/questionsSlice'
import userReducer from './slices/userSlice'
import { configReducer, STATE_KEY as CONFIG_STATE_KEY } from './slices/config'

const rootReducer = combineReducers({
	questions: questionsReducer,
	user: userReducer,
	[CONFIG_STATE_KEY]: configReducer,
})

export default rootReducer
