import React from 'react'
import { flowTypes } from '../../../utils/flowTypes'

import { Button } from '../../common'
import lottie from 'lottie-web'

import './styles.scss'

const WidgetCard = ({
	flow,
	saleFlow,
	image,
	imageStyle,
	video,
	mask,
	title,
	label,
	hideButton,
	buttonText,
	customMaskClass,
	onClick,
	animation,
}) => {
	const animationRef = React.useRef()

	React.useEffect(() => {
		lottie.loadAnimation({
			container: animationRef.current,
			renderer: 'svg',
			loop: true,
			autoplay: true,
			animationData: require('./widgetAnimation.json'),
		})
	}, [])

	return (
		<div className="widget-card">
			{flow === flowTypes.KEYBOARD && (
				<>
					<h2 className={`widget-card__title`}>{title}</h2>
					<span
						className={
							image ? 'widget-card__label' : 'widget-card__label video-label'
						}
					>
						{label}
					</span>
				</>
			)}
			{image ? (
				<>
					<img
						src={`/images/sale/${image}.png`}
						alt="Example of usage."
						className="widget-card__image"
						style={{ ...imageStyle }}
					/>
					{animation && saleFlow !== 'original' && (
						<div className="widget-card__animation" ref={animationRef}></div>
					)}
				</>
			) : (
				<>
					<video
						autoPlay="autoplay"
						loop
						muted
						src={`/videos/sale/${video}.mp4`}
						type="video/mp4"
						className="widget-card__video"
						playsInline
					/>
					<div className="widget-card__wrapper">
						<img
							src={`/images/sale/${mask}.png`}
							alt="Mask."
							className={`widget-card__mask ${customMaskClass}`}
						/>
					</div>
				</>
			)}
			{(flow === flowTypes.LEGAL || flow === flowTypes.DEFAULT) && (
				<>
					<h2 className={`widget-card__title ${image}-${flow}`}>{title}</h2>
					<span
						className={
							image
								? `widget-card__label ${image}`
								: 'widget-card__label video-label'
						}
					>
						{label}
					</span>
				</>
			)}
			{!hideButton && <Button buttonText={buttonText} onClick={onClick} />}
		</div>
	)
}

export { WidgetCard }
