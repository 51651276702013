import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import * as queryString from 'query-string'

import { Footer } from '../../components/common'
import {
	Main,
	WidgetCard,
	Price,
	ReviewsList,
	Modal,
	Awards,
	StatsCard,
	ThemeMain,
	Timer,
	ThemeCard,
	BonusCard,
	ReviewsSlider,
	HorizontalFooter,
} from '../../components/sale'
import {
	getEmail,
	getIsSendConfirmEmail,
	sendConfirmEmail,
	sendConfirmationEmail,
	setFlow,
	getAnnualPlan,
	getDiscountPrice,
	setAnnualPlan,
	setDiscountPrice,
	setTotal,
	setProductId,
	getUserId,
	getIsOnlyLegalFlow,
	setCurrentOffer,
	getSelectedTheme,
	getOfferTime,
	setIsStoppedOfferTimer,
	getSaleScreenTag,
	setSaleScreenTag,
} from '../../redux/slices/userSlice'

import { getSaleScreenABTestValue } from '../../redux/slices/config'

import { QueryHandler } from './handlers/queryHandler/queryHandler'
import {
	ampEvent,
	ampEventWithEventProperty,
	ampSetUserProperty,
} from '../../utils/amplitude'
import { flowTypes } from '../../utils/flowTypes'
import { offersList } from './offersList'
import { notLegalOffer } from './notLegalOffer'

import './styles.scss'
import { paramsToQuery } from '../../utils/paramsToQuery'

const SaleScreen = () => {
	const history = useHistory()
	const { search, pathname } = useLocation()
	const dispatch = useDispatch()
	const isOnlyLegalFlow = useSelector(getIsOnlyLegalFlow)
	const selectedTheme = useSelector(getSelectedTheme)
	const offerTime = useSelector(getOfferTime)
	const saleScreenSlide = useSelector(getSaleScreenTag)

	let flow = flowTypes.LEGAL

	if (pathname.includes('sale11')) {
		flow = flowTypes.DEFAULT
		dispatch(setAnnualPlan({ annualPlan: 9.99 }))
		dispatch(setDiscountPrice({ discountPrice: 9.99 }))
		dispatch(setTotal({ total: 999 }))
		dispatch(setProductId({ productId: process.env.REACT_APP_PRODUCT_ID }))
		dispatch(setCurrentOffer({ currentOffer: notLegalOffer }))
	} else if (pathname.includes('sale22')) {
		flow = flowTypes.KEYBOARD
		dispatch(setAnnualPlan({ annualPlan: 9.99 }))
		dispatch(setDiscountPrice({ discountPrice: 9.99 }))
		dispatch(setTotal({ total: 999 }))
		dispatch(setCurrentOffer({ currentOffer: notLegalOffer }))
	} else if (pathname.includes('sale33')) {
		flow = flowTypes.THEMES
		dispatch(setAnnualPlan({ annualPlan: 9.99 }))
		dispatch(setDiscountPrice({ discountPrice: 9.99 }))
		dispatch(setTotal({ total: 999 }))
		dispatch(setCurrentOffer({ currentOffer: notLegalOffer }))
	}
	dispatch(setFlow({ flow }))

	const data = queryString.parse(search)
	const email = useSelector(getEmail)
	const isSendConfirmEmail = useSelector(getIsSendConfirmEmail)
	const annualPlan = useSelector(getAnnualPlan)
	const discount = useSelector(getDiscountPrice)
	const user_id = useSelector(getUserId)
	const saleScreenABTestValue = useSelector(getSaleScreenABTestValue)

	const [isShownModal, setIsShownModal] = useState(false)
	const [offers, setOffers] = useState(offersList)
	const selectedOffer = offers.find(offer => offer.isSelected)

	const handleSelectOffer = id =>
		setOffers(
			offers.map(offer => {
				if (offer.id === id) {
					offer.isSelected = true
					return offer
				}

				offer.isSelected = false
				return offer
			}),
		)

	const handleScroll = () => {
		document.getElementById('price')?.scrollIntoView({ behavior: 'smooth' })
	}

	const handleScroll2 = () => {
		console.log(document.getElementById('second-price'))
		document
			.getElementById('second-price')
			?.scrollIntoView({ behavior: 'smooth' })
	}

	const nextScreen = () => {
		dispatch(setSaleScreenTag({ saleScreenTag: saleScreenSlide + 1 }))
	}

	const handleBuySubscription = () => {
		setIsShownModal(true)
		setTimeout(() => {
			ampEvent('Checkout_shown')
		}, 0)
		document.body.style.overflow = 'hidden'
	}

	useEffect(() => {
		if (
			(isOnlyLegalFlow && pathname.includes('/sale11')) ||
			(isOnlyLegalFlow && pathname.includes('/sale22')) ||
			(isOnlyLegalFlow && pathname.includes('/sale33'))
		) {
			history.push({
				pathname: `/sale`,
				search: paramsToQuery({
					user_id: user_id,
					email: email,
					salescreen_test: saleScreenABTestValue,
					message: isSendConfirmEmail,
				}),
			})
		}
	}, [isOnlyLegalFlow])

	useEffect(() => {
		dispatch(setAnnualPlan({ annualPlan: selectedOffer.price }))
		dispatch(setDiscountPrice({ discountPrice: 0 }))
		dispatch(setTotal({ total: Math.ceil(selectedOffer.price * 100) }))
		dispatch(setProductId({ productId: selectedOffer.product_id }))
		dispatch(setCurrentOffer({ currentOffer: selectedOffer }))
	}, [selectedOffer])

	useEffect(() => {
		if (flow === flowTypes.LEGAL) {
			document.body.style.backgroundColor = '#0e0e0f'
			setTimeout(() => {
				ampSetUserProperty('sale_type', 'default')
			}, 50)
		} else if (flow === flowTypes.DEFAULT) {
			document.body.style.backgroundColor = '#0e0e0f'
			setTimeout(() => {
				ampSetUserProperty('sale_type', 'quiz')
			}, 50)
		} else if (flow === flowTypes.KEYBOARD) {
			document.body.style.backgroundColor = '#0e0e0f'
			setTimeout(() => {
				ampSetUserProperty('sale_type', 'keyboards')
			}, 50)
		} else if (flow === flowTypes.THEMES) {
			document.body.style.backgroundColor = '#090e18'
			setTimeout(() => {
				ampSetUserProperty('sale_type', 'themes')
			}, 50)
		}
		ampSetUserProperty('origin', window.location.origin)
		if (saleScreenABTestValue === 'test' && flow === flowTypes.THEMES) {
			setTimeout(() => {
				if (data.modal) {
					ampEvent('Checkout_shown')
				}
			}, 100)
		} else {
			setTimeout(() => {
				ampEvent('sales_screen_shown')
				if (data.modal) {
					ampEvent('Checkout_shown')
				}
			}, 100)
		}
	}, [flow])

	useEffect(() => {
		if (
			saleScreenABTestValue === 'test' &&
			flow === flowTypes.THEMES &&
			saleScreenSlide !== 1
		) {
			setTimeout(() => {
				ampEventWithEventProperty('sales_screen_shown', {
					screen: saleScreenSlide,
				})
			}, 100)
		}
	}, [saleScreenSlide])

	useEffect(() => {
		if (offerTime === 0) {
			dispatch(setIsStoppedOfferTimer({ isStoppedOfferTimer: true }))
		}
	}, [offerTime])

	useEffect(() => {
		QueryHandler(email, search, {
			setIsShownModal,
			dispatch,
			history,
		})

		if (!isSendConfirmEmail) {
			const params = [
				{
					key: 'user_id',
					value: user_id,
				},
				{
					key: 'email',
					value: email,
				},
				{
					key: 'salescreen_test',
					value: saleScreenABTestValue,
				},
				{
					key: 'message',
					value: true,
				},
			]

			if (flow === flowTypes.THEMES) {
				params.push({
					key: 'theme',
					value: selectedTheme,
				})
			}

			if (flow !== flowTypes.LEGAL) {
				params.push({
					key: 'flow',
					value: flow,
				})
			}
			dispatch(
				sendConfirmationEmail({
					email,
					type_id: 1,
					project_id: process.env.REACT_APP_PROJECT_ID,
					button_url: {
						front_url: window.location.origin,
						pathname: location.pathname,
						queryParams: params,
					},
				}),
			).then(res => {
				if (+res.payload === 201) {
					dispatch(sendConfirmEmail())
				}
			})
		}
	}, [email, isSendConfirmEmail, saleScreenABTestValue])

	useEffect(() => {
		if (isSendConfirmEmail && data.message === 'false') {
			history.replace({
				pathname,
				search: paramsToQuery({
					...data,
					message: isSendConfirmEmail,
				}),
			})
		}
	}, [isSendConfirmEmail])

	const renderSaleScreen = () => {
		switch (flow) {
			case flowTypes.LEGAL:
			case flowTypes.DEFAULT: {
				return (
					<>
						<Main
							flow={flow}
							saleFlow={saleScreenABTestValue}
							title="Your widgets pack is ready!"
							image={flow === flowTypes.LEGAL ? 'phone-legal' : 'phone-default'}
							text="Choose from 69+ excellent widgets made for you!"
							onClick={handleScroll}
						/>
						<WidgetCard
							flow={flow}
							saleFlow={saleScreenABTestValue}
							video="quick-access"
							mask="quick-access-mask"
							title="Quick access"
							label="Watch the weather, calendar, and check activities during your day quickly!"
							buttonText={flow === flowTypes.LEGAL ? 'Try now' : 'Try for free'}
							onClick={handleScroll}
						/>
						<WidgetCard
							flow={flow}
							saleFlow={saleScreenABTestValue}
							image={
								saleScreenABTestValue === 'original' ? 'phones-a' : 'phones'
							}
							title={
								flow === flowTypes.DEFAULT &&
								saleScreenABTestValue === 'original'
									? 'Top 1 Widgets app in the world'
									: 'Top 1 position in the lifestyle category'
							}
							label={
								flow === flowTypes.DEFAULT &&
								saleScreenABTestValue === 'original'
									? 'We have a lot of type of widgets in our gallery'
									: 'Ranked among Top iOS Apps in the US on App Store Charts'
							}
							buttonText={flow === flowTypes.LEGAL ? 'Try now' : 'Try for free'}
							hideButton={true}
							onClick={handleScroll}
						/>
						<WidgetCard
							flow={flow}
							saleFlow={saleScreenABTestValue}
							image={
								saleScreenABTestValue === 'original'
									? 'charging-screen-a'
									: 'charging-screen'
							}
							title={
								saleScreenABTestValue === 'original'
									? 'Customize your charging screen'
									: 'Express yourself  - create your own widgets'
							}
							label="Use only settings you're comfortable with"
							buttonText={flow === flowTypes.LEGAL ? 'Try now' : 'Try for free'}
							onClick={handleScroll}
							animation={true}
						/>
						<StatsCard
							saleFlow={saleScreenABTestValue}
							image={
								saleScreenABTestValue === 'original' &&
								flow === flowTypes.DEFAULT
									? 'stats-a'
									: 'stats'
							}
							title="Join millions who find comfort with Widgetbox App"
						/>
						<WidgetCard
							flow={flow}
							saleFlow={saleScreenABTestValue}
							image={
								saleScreenABTestValue === 'original'
									? 'customized-keyboard-a'
									: 'customized-keyboard'
							}
							title=" Get customized keyboard"
							label="For Apple Watch and smartphone to use online, social media included"
							buttonText={flow === flowTypes.LEGAL ? 'Try now' : 'Try for free'}
							onClick={handleScroll}
						/>
						<Price
							flow={flow}
							saleFlow={saleScreenABTestValue}
							user_id={user_id}
							setIsShownModal={setIsShownModal}
							selectedOffer={selectedOffer}
							offers={offers}
							handleSelectOffer={handleSelectOffer}
						/>
						<ReviewsList
							flow={flow}
							saleFlow={saleScreenABTestValue}
							title="Word-of-mouth: customers' reviews"
						/>
					</>
				)
			}
			case flowTypes.KEYBOARD: {
				return (
					<>
						<Main
							flow={flow}
							title="Your unique keyboard pack is ready!"
							text="Unlock all 47+ keyboards, fonts and themes!"
							image="keyboard"
							onClick={handleScroll}
						/>
						<WidgetCard
							flow={flow}
							image="keyboard-back"
							title="Customize your keyboard"
							label="Customize your keyboard with aesthetic fonts and themes"
							buttonText="Try for free"
							onClick={handleScroll}
						/>
						<WidgetCard
							flow={flow}
							image="fonts"
							title="Fonts & symbols"
							label="Choose from 30+ fonts"
							hideButton={true}
							buttonText="Try for free"
							onClick={handleScroll}
						/>
						<WidgetCard
							flow={flow}
							image="keyboard-themes"
							imageStyle={{ marginBottom: 30 }}
							title="Keyboard themes"
							label="Find your keyboard style in our amazing themes gallery"
							buttonText="Try for free"
							onClick={handleScroll}
						/>
						<WidgetCard
							flow={flow}
							image="widgets"
							imageStyle={{ marginBottom: 30 }}
							title="69+ Widgets gallery"
							label="Watch the weather, calendar, and check activities during your day quickly!"
							buttonText="Try for free"
							onClick={handleScroll}
						/>
						<Price
							flow={flow}
							saleFlow={saleScreenABTestValue}
							user_id={user_id}
							setIsShownModal={setIsShownModal}
							selectedOffer={selectedOffer}
							offers={offers}
							handleSelectOffer={handleSelectOffer}
						/>
						<Awards />
						<ReviewsList flow={flow} title="User Feedback" />
					</>
				)
			}
			case flowTypes.THEMES: {
				switch (saleScreenABTestValue) {
					case 'original': {
						return (
							<>
								<ThemeMain
									flow={flow}
									saleFlow={saleScreenABTestValue}
									title="Your themes pack is ready!"
									text="Enjoy the theme you made yourself: unique combination and favorite heroes."
									mask="theme-mask"
									onClick={handleScroll}
								/>
								<Timer />
								<h1 className="top-2-app-title">
									Total due today:&nbsp;<span className="accent"> $0.00</span>
								</h1>
								<Price
									flow={flow}
									saleFlow={saleScreenABTestValue}
									user_id={user_id}
									setIsShownModal={setIsShownModal}
									selectedOffer={selectedOffer}
									offers={offers}
									handleSelectOffer={handleSelectOffer}
								/>
								<ThemeCard
									title="What you get?"
									saleFlow={saleScreenABTestValue}
									text="Huge arsenal to create unique themes:"
									image={`${selectedTheme}-theme`}
									button={false}
								/>
								<BonusCard
									number={1}
									title="Customized keyboard"
									text="Large collection of keyboard fonts and backgrounds"
									image={`${selectedTheme}-keyboard`}
									button={false}
									saleFlow={saleScreenABTestValue}
								/>
								<BonusCard
									number={2}
									title="Charging animation"
									text="Use only settings you're comfortable with"
									image="themes-charging"
									button={true}
									buttonText="Try for free"
									onClick={handleScroll2}
									saleFlow={saleScreenABTestValue}
								/>
								<ReviewsSlider
									theme={selectedTheme}
									title="How do users improve their gadgets?"
									button={false}
									saleFlow={saleScreenABTestValue}
								/>
								<ThemeCard
									title="Where can you use it?"
									text="For Apple Watch and Smartphone to use online, social media included"
									image={`${selectedTheme}-using`}
									button={true}
									buttonText="Try for free"
									onClick={handleScroll2}
									saleFlow={saleScreenABTestValue}
								/>
							</>
						)
					}
					case 'test': {
						switch (saleScreenSlide) {
							case 1: {
								return (
									<>
										<div className="sale back2 sale-horizontal">
											<ThemeMain
												flow={flow}
												title="Your themes pack is ready!"
												text="Enjoy the theme you made yourself: unique combination and favorite heroes."
												mask="theme-mask"
												saleFlow={saleScreenABTestValue}
											/>
										</div>
										<HorizontalFooter onClick={nextScreen} />
									</>
								)
							}
							case 2: {
								return (
									<>
										<div className="sale back2 sale-horizontal">
											<ThemeCard
												title="What you get?"
												text="Huge arsenal to create unique themes:"
												image={`${selectedTheme}-theme`}
											/>
										</div>
										<HorizontalFooter onClick={nextScreen} />
									</>
								)
							}
							case 3: {
								return (
									<>
										<div className="sale back2 sale-horizontal">
											<BonusCard
												number={1}
												title="Customized keyboard"
												text="Large collection of keyboard fonts and backgrounds"
												image={`${selectedTheme}-keyboard`}
											/>
										</div>
										<HorizontalFooter onClick={nextScreen} />
									</>
								)
							}
							case 4: {
								return (
									<>
										<div className="sale back2 sale-horizontal">
											<BonusCard
												number={2}
												title="Charging animation"
												text="Use only settings you're comfortable with"
												image="themes-charging"
											/>
										</div>
										<HorizontalFooter onClick={nextScreen} />
									</>
								)
							}
							case 5: {
								return (
									<>
										<div className="sale back2 sale-horizontal">
											<ThemeCard
												title="Where can you use it?"
												text="For Apple Watch and Smartphone to use online, social media included"
												image={`${selectedTheme}-using`}
												saleFlow={saleScreenABTestValue}
											/>
										</div>
										<HorizontalFooter onClick={nextScreen} />
									</>
								)
							}
							case 6: {
								return (
									<>
										<div className="sale back2 sale-horizontal">
											<ReviewsSlider
												theme={selectedTheme}
												title="What people say?"
												saleFlow={saleScreenABTestValue}
											/>
										</div>
										<HorizontalFooter onClick={nextScreen} />
									</>
								)
							}
							case 7: {
								return (
									<>
										<div className="sale back2 sale-horizontal">
											<Timer margin={30} />
											<Price
												flow={flow}
												saleFlow={saleScreenABTestValue}
												user_id={user_id}
												setIsShownModal={setIsShownModal}
												selectedOffer={selectedOffer}
												offers={offers}
												handleSelectOffer={handleSelectOffer}
											/>
										</div>
										<HorizontalFooter onClick={handleBuySubscription} />
									</>
								)
							}
							default: {
								return <p>Component not found</p>
							}
						}
					}
				}
			}
		}
	}

	return (
		<div
			className={`sale ${
				flow === flowTypes.KEYBOARD || flow === flowTypes.THEMES ? 'back2' : ''
			}`}
		>
			{renderSaleScreen()}
			{flow === flowTypes.THEMES && saleScreenABTestValue === 'test' ? (
				<></>
			) : (
				<Footer
					flow={flow}
					saleFlow={saleScreenABTestValue}
					user_id={user_id}
					setIsShownModal={setIsShownModal}
					selectedOffer={selectedOffer}
					offers={offers}
					handleSelectOffer={handleSelectOffer}
				/>
			)}
			<Modal
				total={
					flow === flowTypes.LEGAL ? Math.ceil(selectedOffer.price * 100) : 999
				}
				isShownModal={isShownModal}
				setIsShownModal={setIsShownModal}
				annualPlan={annualPlan}
				discount={discount}
				productId={
					flow === flowTypes.LEGAL
						? selectedOffer.product_id
						: process.env.REACT_APP_PRODUCT_ID
				}
				lifetime={false}
			/>
		</div>
	)
}

export { SaleScreen }
