import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { getUserId } from '../../../../../../redux/slices/userSlice'

import { ampEvent } from '../../../../../../utils/amplitude'
import { paramsToQuery } from '../../../../../../utils/paramsToQuery'

import { ReactComponent as ArrowIcon } from './images/burger-option-arrow.svg'
import './styles.scss'

const BurgerMenu = ({ isOpen, handleCloseBurgerMenu }) => {
	const [isOpenTerms, setIsOpenTerms] = useState(false)
	const user_id = useSelector(getUserId)
	const toggleTerms = () => setIsOpenTerms(!isOpenTerms)

	useEffect(() => {
		if (isOpen) {
			setTimeout(() => {
				ampEvent('menu_shown')
			}, 0)
		}
		setIsOpenTerms(false)
	}, [isOpen])

	return (
		<div
			className={`burger-menu-container ${
				isOpen ? 'burger-menu-container--open' : ''
			}`}
		>
			<div className="burger-menu">
				<div className="burger-menu-options">
					<div className="burger-menu__option">
						<Link
							onClick={() => handleCloseBurgerMenu('/account')}
							to={{
								pathname: '/account',
								search: paramsToQuery({ user_id }),
							}}
						>
							My Account
						</Link>
					</div>
					<div className="burger-menu__option">
						<Link
							onClick={handleCloseBurgerMenu}
							to={{
								pathname: '/faq',
								search: paramsToQuery({ user_id }),
							}}
						>
							FAQ
						</Link>
					</div>
					<div
						className={`burger-menu__option ${
							isOpenTerms ? 'burger-menu__option--open' : ''
						}`}
						onClick={toggleTerms}
					>
						<span>Terms & Policies</span>
						<ArrowIcon />
					</div>
				</div>
				<div
					className={`terms-and-policies ${
						isOpenTerms ? 'terms-and-policies--open' : ''
					}`}
				>
					<div className="terms-and-policies__option">
						<Link
							onClick={handleCloseBurgerMenu}
							to={{
								pathname: '/money-back-policy',
								search: paramsToQuery({ user_id }),
							}}
						>
							Money-Back Policy
						</Link>
					</div>
					<div className="terms-and-policies__option">
						<Link
							onClick={handleCloseBurgerMenu}
							to={{
								pathname: '/privacy-policy',
								search: paramsToQuery({ user_id }),
							}}
						>
							Privacy policy
						</Link>
					</div>
					<div className="terms-and-policies__option">
						<Link
							onClick={handleCloseBurgerMenu}
							to={{
								pathname: '/terms-of-use',
								search: paramsToQuery({ user_id }),
							}}
						>
							Terms and Conditions of Use
						</Link>
					</div>
					<div className="terms-and-policies__option">
						<Link
							onClick={handleCloseBurgerMenu}
							to={{
								pathname: '/subscription-terms',
								search: paramsToQuery({ user_id }),
							}}
						>
							Subscription Terms
						</Link>
					</div>
				</div>
				<div className="burger-menu-contacts">
					<img
						src="/images/common/burgerMenu/email-icon.svg"
						alt="email-icon"
						className="burger-menu-contacts__email-icon"
					/>
					<div className="contacts-info">
						<p className="contacts-info__label">Contact Us</p>
						<a
							href="https://mail.google.com/mail/?view=cm&fs=1&to=support@widget-box.me"
							target="_blank"
							rel="noreferrer"
							className="contacts-info__link"
						>
							support@widget-box.me
						</a>
					</div>
				</div>
			</div>
		</div>
	)
}

export default BurgerMenu
