import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { withRouter, useLocation, useHistory } from 'react-router-dom'
import * as queryString from 'query-string'
import * as cookie from 'cookie'

import Router from './Router'
import { ampSetUserProperty } from './utils/amplitude'
import {
	setFlow,
	setFbc,
	setFbp,
	getUserId,
	setUserId,
	setIsOnlyLegalFlow,
	setCountryCode,
	getCountryCode,
	setSelectedTheme,
} from './redux/slices/userSlice'
import {
	setSecondFlow,
	startQuiz,
	setFirstFlow,
	setThirdFlow,
} from './redux/slices/questionsSlice'
import { flowTypes } from './utils/flowTypes'
import { paramsToQuery } from './utils/paramsToQuery'
import { onlyLegalFlowCountry } from './model'
import { getValueByKey } from './utils/remoteConfigs'
import { setSaleScreenABTestValue } from './redux/slices/config'
import { productPropertyFetch } from './utils/analyticsRequests'

const App = () => {
	const { pathname } = useLocation()
	const dispatch = useDispatch()
	const params = queryString.parse(location.search)
	const user_id = useSelector(getUserId)
	const history = useHistory()
	const countryCode = useSelector(getCountryCode)

	useEffect(() => {
		setTimeout(() => {
			const cookies = cookie.parse(document.cookie)
			dispatch(setFbc({ fbc: cookies._fbc || '' }))
			dispatch(setFbp({ fbp: cookies._fbp || '' }))
		}, 2000)
	})

	useEffect(() => {
		productPropertyFetch(user_id || window.user_id)
		if (!countryCode) {
			try {
				fetch(
					'https://ipgeolocation.abstractapi.com/v1/?api_key=1c4acaa10922477bb9fb47f82a780a0b',
				)
					.then(res => res.json())
					.then(res => {
						dispatch(setCountryCode({ countryCode: res.country_code }))
					})
			} catch (e) {
				console.error(e)
			}
		}
		if (!params.salescreen_test) {
			getValueByKey('salescreen_test').then(res => {
				console.log('A/B test', res)
				dispatch(setSaleScreenABTestValue(res))
			})
		}
		if (params.theme) {
			dispatch(setSelectedTheme({ selectedTheme: params.theme }))
		}
	}, [])

	useEffect(() => {
		if (onlyLegalFlowCountry.includes(countryCode)) {
			dispatch(setIsOnlyLegalFlow({ isOnlyLegalFlow: true }))
			dispatch(setFlow({ flow: '' }))
		}
	}, [countryCode])

	useEffect(() => {
		setTimeout(() => {
			if (user_id) {
				if (countryCode) {
					ampSetUserProperty('country', countryCode)
				}
			}
		}, 0)
	}, [pathname, user_id, countryCode])

	React.useEffect(() => {
		window.scrollTo(0, 0)

		Object.keys(params).forEach(elem => {
			if (
				(elem === 'flow' && !params[elem]) ||
				(elem === 'flow' && !params.user_id)
			) {
				return
			} else if (elem === 'user_id') {
				dispatch(setUserId({ user_id: params[elem] }))
			}
			ampSetUserProperty(elem, params[elem])
		})
	}, [pathname])

	useEffect(() => {
		window.history.scrollRestoration = 'manual'
		const isLegalFlow = onlyLegalFlowCountry.includes(countryCode)
		if (isLegalFlow) {
			dispatch(setFlow({ flow: flowTypes.LEGAL }))
			dispatch(setFirstFlow())
			history.push({
				pathname: location.pathname,
				search: paramsToQuery({ user_id: user_id || window.id, ...params }),
			})
		} else if (params.flow === '33') {
			dispatch(setFlow({ flow: flowTypes.THEMES }))
			dispatch(setThirdFlow())
		} else if (params.flow === '22') {
			dispatch(setFlow({ flow: flowTypes.KEYBOARD }))
			dispatch(setSecondFlow())
		} else if ((!params.flow && pathname === '/') || params.flow === '11') {
			if (params.flow === '11') {
				dispatch(setFlow({ flow: flowTypes.DEFAULT }))
			} else {
				dispatch(setFlow({ flow: flowTypes.LEGAL }))
			}
			dispatch(startQuiz())
		}
	}, [countryCode])

	useEffect(() => {
		if (!params.user_id) {
			history.push({
				pathname: location.pathname,
				search: paramsToQuery({
					user_id: user_id || window.user_id,
					...params,
				}),
			})

			dispatch(setUserId({ user_id: user_id || window.user_id }))
		} else {
			dispatch(setUserId({ user_id: params.user_id || window.user_id }))
		}
	}, [location.href])

	return (
		<div className="app">
			<Router />
		</div>
	)
}

export default withRouter(App)
