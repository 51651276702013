import React from 'react'

import './styles.scss'

const Success = () => {
	return (
		<div className="unsubscribe-success">
			<img
				className="unsubscribe-success__img"
				src="/images/unsubscribe/man.png"
				alt="succes-man"
			/>
			<div className="unsubscribe-success__text">
				{'Subscription is successfully cancelled'}
			</div>
		</div>
	)
}
export default Success
