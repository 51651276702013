import React from 'react'

import { Button } from '../../common'

import './styles.scss'

const BonusCard = ({
	number,
	title,
	text,
	image,
	button,
	buttonText,
	onClick,
	saleFlow,
}) => {
	let width
	if (number === 2) {
		width = 335
	}

	let marginBottom
	let marginTop
	let padding

	if (saleFlow === 'original') {
		marginBottom = 80
		marginTop = 0
		padding = 0
	} else {
		marginTop = 20
		marginBottom = 10
		padding = 69
	}

	return (
		<div
			className="bonus-card"
			style={{
				marginTop: marginTop,
				marginBottom: marginBottom,
				paddingTop: padding,
			}}
		>
			<div className="bonus-card__wrapper">
				<div className="bonus-card__bonus">
					<img
						className="bonus-card__bonus-img"
						src="/images/sale/bonus.png"
						alt="bonus"
					/>
					<span className="bonus-card__bonus-label">Bonus {number}</span>
				</div>
			</div>
			<img
				className="bonus-card__image"
				src={`/images/sale/${image}.png`}
				alt={image}
				style={{ width: width }}
			/>
			<h1 className="bonus-card__title">{title}</h1>
			<p className="bonus-card__text">{text}</p>
			{button && (
				<Button buttonText={buttonText} onClick={onClick} width={335} />
			)}
		</div>
	)
}

export { BonusCard }
