import React, { useState } from 'react'
import TextareaAutosize from 'react-textarea-autosize'

import { Button } from '../../../../components/common/button'

import './styles.scss'

const Question = ({
	unsubscribeAnswers,
	textareaValue,
	setTextareaValue,
	onTextAreaClick,
	isShowSendBtn,
	onAnswerClick,
	onSendBtnClick,
	onSkipBtnClick,
}) => {
	const [onInputFocus, setOnInputFocus] = useState(false)
	return (
		<div className="unsubscribe-qustion">
			<div className="unsubscribe-qustion__header">
				<div className="unsubscribe-qustion__header-title">
					Cancel Subscription
				</div>
				<div className="unsubscribe-qustion__header-subtitle">
					Why would like to unsubscribe?
				</div>
			</div>
			<div className="unsubscribe-qustion__answers">
				{unsubscribeAnswers.map(answer => {
					return (
						<div
							key={answer.id}
							className={`unsubscribe-qustion__answers-item ${
								answer.selected
									? 'unsubscribe-qustion__answers-item--selected'
									: ''
							}`}
							onClick={e => {
								onAnswerClick(answer.id, e)
							}}
						>
							{answer.text}
						</div>
					)
				})}
				<div></div>
				<TextareaAutosize
					rows={1}
					maxRows={10}
					onFocus={() => {
						setOnInputFocus(true)
					}}
					onBlur={() => {
						setOnInputFocus(false)
					}}
					maxLength="500"
					className="unsubscribe-qustion__answers-input"
					placeholder="Enter your option"
					value={textareaValue}
					onChange={e => {
						setTextareaValue(e.target.value)
					}}
					onClick={onTextAreaClick}
				/>
				<span
					className={`unsubscribe-qustion__answers-hover ${
						textareaValue ? 'unsubscribe-qustion__answers-hover--active' : ''
					} ${onInputFocus ? 'unsubscribe-qustion__answers-hover--focus' : ''}`}
				>
					{'Enter your option'}
				</span>
			</div>
			<div className="unsubscribe-qustion__btns">
				{isShowSendBtn && (
					<Button
						className="unsubscribe-qustion__btns-send"
						// width={331}
						buttonText="Send"
						onClick={onSendBtnClick}
						disabled={!textareaValue}
					/>
				)}
				<button
					className="unsubscribe-qustion__btns-skip"
					onClick={onSkipBtnClick}
				>
					{'Skip'}
				</button>
			</div>
		</div>
	)
}

export default Question
