import React from 'react'
import { Link } from 'react-router-dom'
import { flowTypes } from '../../../utils/flowTypes'

import { paramsToQuery } from '../../../utils/paramsToQuery'

import './styles.scss'

const Footer = ({ flow, user_id, checkboxes, updateCheckboxStatus }) => {
	return (
		<div className={`email-footer ${flow === flowTypes.LEGAL ? 'check' : ''}`}>
			{flow === flowTypes.LEGAL ? (
				<div className="email-footer__agreement">
					<div className="email-footer__agreement__row">
						<img
							src={`/images/email/radiobutton${
								checkboxes[0] ? '-check' : ''
							}.png`}
							alt="Checkbox."
							className="email-footer__agreement__row__checkbox"
							onClick={() => updateCheckboxStatus(0)}
						/>
						<span className="email-footer__agreement__row__text">
							By continuing I agree with{' '}
							<Link
								className="email-footer__agreement__row__text__link"
								to={{
									pathname: '/terms-of-use',
									search: paramsToQuery({ user_id }),
								}}
							>
								Terms of Service,
							</Link>{' '}
							<Link
								className="email-footer__agreement__row__text__link"
								to={{
									pathname: '/privacy-policy',
									search: paramsToQuery({ user_id }),
								}}
							>
								Privacy Policy,
							</Link>{' '}
							<Link
								className="email-footer__agreement__row__text__link"
								to={{
									pathname: '/money-back-policy',
									search: paramsToQuery({ user_id }),
								}}
							>
								Money-Back Policy,
							</Link>{' '}
							<Link
								className="email-footer__agreement__row__text__link"
								to={{
									pathname: '/subscription-terms',
									search: paramsToQuery({ user_id }),
								}}
							>
								Subscription Terms,
							</Link>{' '}
							<Link
								className="email-footer__agreement__row__text__link"
								to={{
									pathname: '/privacy-policy',
									search: paramsToQuery({ user_id }),
								}}
							>
								Cookie policy
							</Link>
						</span>
					</div>
					<div className="email-footer__agreement__row">
						<img
							src={`/images/email/radiobutton${
								checkboxes[1] ? '-check' : ''
							}.png`}
							alt="Checkbox."
							className="email-footer__agreement__row__checkbox"
							onClick={() => updateCheckboxStatus(1)}
						/>
						<span className="email-footer__agreement__row__text">
							I would like to receive updates about products, services, and
							special offers from WidgetBox via email
						</span>
					</div>
				</div>
			) : (
				<span className="email-footer__content">
					By continuing you accept our{' '}
					<Link
						className="email-footer__content__link"
						to={{
							pathname: '/privacy-policy',
							search: paramsToQuery({ user_id }),
						}}
					>
						Privacy Policy
					</Link>{' '}
					,{' '}
					<Link
						className="email-footer__content__link"
						to={{
							pathname: '/terms-of-use',
							search: paramsToQuery({ user_id }),
						}}
					>
						Terms of Service
					</Link>{' '}
					and{' '}
					<Link
						className="email-footer__content__link"
						to={{
							pathname: '/subscription-terms',
							search: paramsToQuery({ user_id }),
						}}
					>
						Subscription Terms
					</Link>
				</span>
			)}
		</div>
	)
}

export { Footer }
