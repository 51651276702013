import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'

import { Header1, Header2, UpsaleMain, Footer } from '../../components/upsale'
import {
	getCardToken,
	getEmail,
	getFbc,
	getFbp,
	getFirstUpsale,
	getPayPalToken,
	getSecondUpsale,
	getUserId,
	setFirstUpsale,
	setSecondUpsale,
} from '../../redux/slices/userSlice'
import {
	ampEvent,
	ampSetUserProperty,
	ampEventWithEventProperty,
} from '../../utils/amplitude'
import {
	cardSubscriptionRecurentRequest,
	payPalSubscriptionRecurentRequest,
} from '../../api/paymentRequests'
import { paramsToQuery } from '../../utils/paramsToQuery'
import './styles.scss'
import { purchasePropertyFetch } from '../../utils/analyticsRequests'
import { productNamesByProductId } from '../../model/productNamesByProductId'

const UpsaleScreen = () => {
	const { pathname } = useLocation()
	const history = useHistory()
	const dispatch = useDispatch()

	const email = useSelector(getEmail)
	const payPalToken = useSelector(getPayPalToken)
	const cardToken = useSelector(getCardToken)
	const firstUpsale = useSelector(getFirstUpsale)
	const secondUpsale = useSelector(getSecondUpsale)
	const fbc = useSelector(getFbc)
	const fbp = useSelector(getFbp)
	const user_id = useSelector(getUserId)

	const upsaleType = pathname.includes('upsale1') ? '/upsale1' : '/upsale2'
	const [btnDisabled, setBtnDisabled] = React.useState(false)

	React.useEffect(() => {
		setTimeout(() => {
			if (upsaleType === '/upsale1') {
				ampEvent('upsell_shown_1')
			} else {
				ampEvent('upsell_shown_2')
			}
		}, 0)
	}, [upsaleType])

	const onUpgradeBtnClick = (body, path) => {
		setBtnDisabled(true)

		if (path === '/upsale2') {
			if (payPalToken && !firstUpsale) {
				payPalSubscriptionRecurentRequest({ ...body, token: payPalToken })
					.then(res => {
						successUpgradeUpsale1(path)
						ampEventWithEventProperty('upsell_purchase', {
							upsell_number: 1,
							upsell_name: productNamesByProductId[body.product_id],
							upsell_price: 9.99,
							currency: 'USD',
							payment_type: 'PAYPAL',
							upsell_id: body.product_id,
						})

						purchasePropertyFetch(
							{
								upsell_number: 1,
								upsell_name: productNamesByProductId[body.product_id],
								upsell_price: 9.99,
								currency: 'USD',
								payment_type: 'PAYPAL',
								upsell_id: body.product_id,
							},
							user_id,
						)
					})
					.catch(res => {
						ampSetUserProperty('upsale_1', 'declined')
					})
			} else if (cardToken && !firstUpsale) {
				cardSubscriptionRecurentRequest({ ...body, token: cardToken })
					.then(res => {
						successUpgradeUpsale1(path)
						ampEventWithEventProperty('upsell_purchase', {
							upsell_number: 1,
							upsell_name: productNamesByProductId[body.product_id],
							upsell_price: 9.99,
							currency: 'USD',
							payment_type: 'CARD',
							upsell_id: body.product_id,
						})

						purchasePropertyFetch(
							{
								upsell_number: 1,
								upsell_name: productNamesByProductId[body.product_id],
								upsell_price: 9.99,
								currency: 'USD',
								payment_type: 'CARD',
								upsell_id: body.product_id,
							},
							user_id,
						)
					})
					.catch(error => {
						console.log('error', error)
						ampSetUserProperty('upsale_1', 'declined')
					})
			} else {
				setBtnDisabled(false)
				ampSetUserProperty('upsale_1', 'declined')
				history.push({
					pathname: path,
					search: paramsToQuery({
						user_id,
					}),
				})
			}
		} else if (path === '/success') {
			if (payPalToken && !secondUpsale) {
				payPalSubscriptionRecurentRequest({ ...body, token: payPalToken })
					.then(res => {
						successUpgradeUpsale2(path)
						ampEventWithEventProperty('upsell_purchase', {
							upsell_number: 2,
							upsell_name: productNamesByProductId[body.product_id],
							upsell_price: 9.99,
							currency: 'USD',
							payment_type: 'PAYPAL',
							upsell_id: body.product_id,
						})

						purchasePropertyFetch(
							{
								upsell_number: 2,
								upsell_name: productNamesByProductId[body.product_id],
								upsell_price: 9.99,
								currency: 'USD',
								payment_type: 'PAYPAL',
								upsell_id: body.product_id,
							},
							user_id,
						)
					})
					.catch(res => {
						ampSetUserProperty('upsale_2', 'declined')
					})
			} else if (cardToken && !secondUpsale) {
				cardSubscriptionRecurentRequest({ ...body, token: cardToken })
					.then(res => {
						successUpgradeUpsale2(path)
						ampEventWithEventProperty('upsell_purchase', {
							upsell_number: 2,
							upsell_name: productNamesByProductId[body.product_id],
							upsell_price: 9.99,
							currency: 'USD',
							payment_type: 'CARD',
							upsell_id: body.product_id,
						})

						purchasePropertyFetch(
							{
								upsell_number: 2,
								upsell_name: productNamesByProductId[body.product_id],
								upsell_price: 9.99,
								currency: 'USD',
								payment_type: 'CARD',
								upsell_id: body.product_id,
							},
							user_id,
						)
					})
					.catch(res => {
						ampSetUserProperty('upsale_2', 'declined')
					})
			} else {
				setBtnDisabled(false)
				ampSetUserProperty('upsale_2', 'declined')
				history.push({
					pathname: path,
					search: paramsToQuery({
						user_id,
					}),
				})
			}
		} else {
			setBtnDisabled(false)
			ampSetUserProperty('upsale_2', 'declined')
			history.push({
				pathname: path,
				search: paramsToQuery({
					user_id,
				}),
			})
		}
	}

	const onSkipBtnClick = path => {
		if (path === '/upsale2') {
			ampSetUserProperty('upsale_1', 'declined')
			history.push({
				pathname: path,
				search: paramsToQuery({
					user_id,
				}),
			})
		} else {
			ampSetUserProperty('upsale_2', 'declined')
			history.push({
				pathname: path,
				search: paramsToQuery({
					user_id,
				}),
			})
		}
	}

	React.useEffect(() => {
		document.getElementById('upsale')?.scrollIntoView()
	}, [onUpgradeBtnClick, onSkipBtnClick])

	const successUpgradeUpsale1 = path => {
		setBtnDisabled(false)
		ampSetUserProperty('upsale_1', 'upgraded')
		dispatch(setFirstUpsale())
		history.push({
			pathname: path,
			search: paramsToQuery({
				user_id,
			}),
		})
	}

	const successUpgradeUpsale2 = path => {
		setBtnDisabled(false)
		ampSetUserProperty('upsale_2', 'upgraded')
		dispatch(setSecondUpsale())
		history.push({
			pathname: path,
			search: paramsToQuery({
				user_id,
			}),
		})
	}

	return upsaleType === '/upsale1' ? (
		<div className="upsale" id="upsale">
			<div className="upsale-top">
				<Header1 />
				<UpsaleMain image="upsale1" />
			</div>
			<Footer
				onUpgradeBtnClick={() =>
					onUpgradeBtnClick(
						{
							email,
							amount: 999,
							product_id: process.env.REACT_APP_UPSELL_1_PRODUCT_ID,
							pixel_id: process.env.REACT_APP_PIXEL_ID,
							external_id: window.id,
							userAgent: navigator.userAgent,
							user_source: window.location.origin,
							fbp: fbp,
							fbc: fbc,
							user_id: user_id,
						},
						'/upsale2',
					)
				}
				onSkipBtnClick={() => onSkipBtnClick('/upsale2')}
				btnDisabled={btnDisabled}
			/>
		</div>
	) : (
		<div className="upsale" id="upsale">
			<div className="upsale-top">
				<Header2 />
				<UpsaleMain image="upsale2" />
			</div>
			<Footer
				onUpgradeBtnClick={() =>
					onUpgradeBtnClick(
						{
							email,
							amount: 999,
							product_id: process.env.REACT_APP_UPSELL_2_PRODUCT_ID,
							pixel_id: process.env.REACT_APP_PIXEL_ID,
							external_id: window.id,
							userAgent: navigator.userAgent,
							user_source: window.location.origin,
							fbp: fbp,
							fbc: fbc,
							user_id: user_id,
						},
						'/success',
					)
				}
				onSkipBtnClick={() => onSkipBtnClick('/success')}
				btnDisabled={btnDisabled}
			/>
		</div>
	)
}

export { UpsaleScreen }
