import React from 'react'

import { Button } from '../../common'

import { ampEvent } from '../../../utils/amplitude'

import './styles.scss'

const Offer = ({ setIsShownModal }) => {
	const handleBuySubscription = () => {
		setIsShownModal(true)
		setTimeout(() => {
			ampEvent('Checkout_shown')
		}, 0)
		document.body.style.overflow = 'hidden'
	}

	return (
		<div className="discount-offer">
			<img
				src="/images/discount/custom-widgets.png"
				alt="Example of usage."
				className="discount-offer__image"
			/>
			<h2 className="discount-offer__title">
				Make it Anyway.
				<br />
				Make it with WidgetBox!
			</h2>
			<Button buttonText="Get my Discount" onClick={handleBuySubscription} />
		</div>
	)
}

export { Offer }
