import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import * as queryString from 'query-string'

import {
	getEmail,
	getFbc,
	getFbp,
	getFlow,
	getUserId,
	setBill,
	setIsStoppedOfferTimer,
} from '../../../redux/slices/userSlice'
import {
	payPalRequest,
	cardRequest,
} from './handlers/requestsHandler/requestsHandler'
import {
	payPalHandler,
	cardHandler,
} from './handlers/listenerHandler/listenerHandler'
import { merchantDataHandler } from './handlers/merchantDataHandler/merchantDataHandler'
import { flowTypes } from '../../../utils/flowTypes'

import './styles.scss'
import { paramsToQuery } from '../../../utils/paramsToQuery'

const Modal = ({
	total,
	isShownModal,
	setIsShownModal,
	annualPlan,
	discount,
	productId,
	lifetime = false,
}) => {
	const history = useHistory()
	const dispatch = useDispatch()
	const { search, pathname } = useLocation()
	const data = queryString.parse(search)

	const inputEl = useRef(undefined)
	const cardRef = useRef(undefined)

	const fbc = useSelector(getFbc)
	const fbp = useSelector(getFbp)
	const email = useSelector(getEmail)
	const user_id = useSelector(getUserId)

	const flow = useSelector(getFlow)
	const [payPalPayment, setPayPalPayment] = useState(true)

	let totalToDisplay

	if (pathname === '/discount') {
		totalToDisplay = (0).toFixed(2)
	} else if (pathname === '/unsubscribe/offer') {
		totalToDisplay = 9.99
	} else totalToDisplay = (annualPlan - discount).toFixed(2)

	useEffect(() => {
		if (isShownModal) {
			dispatch(setIsStoppedOfferTimer({ isStoppedOfferTimer: true }))
			fetch(`${process.env.REACT_APP_GET_USER_URL}/${email}`)
				.then(res => res.json())
				.then(({ premium }) => {
					if (premium && pathname !== '/unsubscribe/offer') {
						document.body.style.overflow = 'auto'
						dispatch(
							setBill({
								type: 'UNTRACKED',
							}),
						)
						history.push({
							pathname: '/success',
							search: paramsToQuery({ billed: true }),
						})
					}
				})
		}
	}, [isShownModal])

	useEffect(() => {
		payPalRequest({
			email: email || data.email,
			amount: total,
			product_id: productId,
			pixel_id: process.env.REACT_APP_PIXEL_ID,
			external_id: window.id,
			userAgent: navigator.userAgent,
			user_source: window.location.origin,
			user_id: user_id,
			fbp: fbp,
			fbc: fbc,
		}).then(res => {
			const script = document.createElement('script')
			script.src = res.url
			inputEl?.current?.addEventListener(
				'order-processed',
				payPalHandler(pathname, history, dispatch, total, lifetime, productId),
			)
			document.body.appendChild(script)
			setTimeout(() => {
				inputEl?.current?.addEventListener(
					'order-processed',
					payPalHandler(
						pathname,
						history,
						dispatch,
						total,
						lifetime,
						productId,
					),
				)
			}, 3000)
		})
		cardRequest({
			email: email || data.email,
			amount: total,
			product_id: productId,
			pixel_id: process.env.REACT_APP_PIXEL_ID,
			external_id: window.id,
			userAgent: navigator.userAgent,
			user_source: window.location.origin,
			user_id: user_id,
			fbp: fbp,
			fbc: fbc,
		}).then(res => {
			const data = merchantDataHandler(res)
			if (cardRef.current !== null) {
				cardRef.current.innerHTML = ''
			}
			window.PaymentFormSdk.init(data)
			window.addEventListener(
				'message',
				cardHandler(pathname, history, dispatch, total, lifetime, productId),
			)
			setTimeout(() => {
				document
					.getElementById('solid-payment-form-container')
					?.addEventListener(
						'message',
						cardHandler(
							pathname,
							history,
							dispatch,
							total,
							lifetime,
							productId,
						),
					)
			}, 3000)
		})
	}, [productId])

	useEffect(() => {
		if (isShownModal) {
			document.body.style.overflow = 'hidden'
		}
		if (!isShownModal) {
			dispatch(setIsStoppedOfferTimer({ isStoppedOfferTimer: false }))
		}
	}, [isShownModal])

	const closeModalBtnHandler = () => {
		dispatch(setIsStoppedOfferTimer({ isStoppedOfferTimer: false }))
		setIsShownModal(false)
		document.body.style.overflow = 'visible'
		const queryParams = new URLSearchParams(search)
		if (queryParams.has('modal')) {
			queryParams.delete('modal')
			history.replace({
				search: decodeURIComponent(queryParams.toString()),
			})
		}
		if (queryParams.has('is_confirmation_email')) {
			queryParams.delete('is_confirmation_email')
			history.replace({
				search: decodeURIComponent(queryParams.toString()),
			})
		}
		if (pathname.includes('/sale') && flow !== flowTypes.LEGAL) {
			history.push({
				pathname: `/discount`,
				search: decodeURIComponent(queryParams.toString()),
			})
		}
	}

	return (
		<div
			className={`backdrop ${isShownModal ? 'show' : 'hide'}
            `}
		>
			<div className="modal">
				<div className="wrapper">
					<div className="modal__header">
						<div className="modal__header__btn">
							<div className="modal__header__btn-line"></div>
							<img
								src="/images/sale/modal/exit-modal.png"
								alt="X"
								className="modal__header__btn-close"
								onClick={closeModalBtnHandler}
							/>
						</div>
						<h2 className="modal__header__title">
							Select a secure payment method
						</h2>
					</div>
					<div className="modal__choice">
						<div
							className={`modal__choice__card ${
								payPalPayment && 'active-payment'
							}`}
							onClick={() => {
								setPayPalPayment(true)
							}}
						>
							<div className="content">
								<span>PayPal</span>
								<img
									src={`/images/sale/modal/paypal-${
										!payPalPayment ? 'de' : ''
									}active.png`}
									alt="PayPal icon."
									className="paypal-image"
								/>
							</div>
							<div className="check">
								<img
									src="/images/sale/pick.png"
									alt="Pick icon."
									className="check-icon"
								/>
							</div>
						</div>
						<div
							className={`modal__choice__card ${
								!payPalPayment && 'active-payment'
							}`}
							onClick={() => {
								setPayPalPayment(false)
							}}
						>
							<div className="content">
								<span>Credit cards</span>
								<img
									src={`/images/sale/modal/credits-${
										payPalPayment ? 'de' : ''
									}active.png`}
									alt="PayPal icon."
									className="credits-image"
								/>
							</div>
							<div className="check">
								<img
									src="/images/sale/pick.png"
									alt="Pick icon."
									className="check-icon"
								/>
							</div>
						</div>
					</div>
				</div>
				<div className="modal__content">
					<div className="modal__price">
						<div className="modal__price__total">
							<span className="modal__price__total__title">Total:</span>
							<span className="modal__price__total__price">
								{flow === flowTypes.LEGAL &&
								pathname !== '/unsubscribe/offer' &&
								pathname !== '/discount'
									? '$0.99'
									: `$${totalToDisplay}`}
							</span>
						</div>
					</div>

					{/* {isShownModal ? ( */}
					<div className="modal__paypal">
						<div
							id="paypal-button"
							className={
								payPalPayment
									? 'modal__button'
									: 'modal__button show-payment-type'
							}
							ref={inputEl}
							style={{
								border: 'none',
								background: 'white',
								marginTop: '80px',
							}}
						></div>
					</div>
					{/* ) : (
						<></>
					)} */}

					<div
						id="solid-payment-form-container"
						className={payPalPayment ? 'show-payment-type' : ''}
						ref={cardRef}
					></div>

					<span className="modal__privacy">
						{payPalPayment && 'The safer, easier way to pay'}
					</span>
				</div>
			</div>
		</div>
	)
}

export { Modal }
