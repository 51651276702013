import firebase from 'firebase/compat/app'
import {
	fetchAndActivate,
	getRemoteConfig,
	getValue,
} from 'firebase/remote-config'
import { ampSetUserProperty } from './amplitude'

const firebaseConfig = {
	apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
	authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
	projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
	storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
	messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
	appId: process.env.REACT_APP_FIREBASE_APP_ID,
}

firebase.initializeApp(firebaseConfig)

const defaultConfig = {
	saleScreenABTestValue: '',
}

const remoteConfig = getRemoteConfig()
remoteConfig.defaultConfig = defaultConfig

const getValueByKey = async key => {
	await fetchAndActivate(remoteConfig)
	const value = getValue(remoteConfig, key)._value
	if (key === 'salescreen_test') {
		return value
	}
	ampSetUserProperty(key, value)
	return value
}
export { getValueByKey }
export { defaultConfig }
export { remoteConfig }
