import { types } from './questionTypes'

export const firstFlow = [
	{
		id: '0',
		componentType: types.SQUARE_ANSWERS,
		question: 'Hi there! Find your theme',
		answers: [1, 2, 3, 4, 5, 6],
		selected: [],
		buttonText: 'Continue',
		skipable: false,
		isMultiple: false,
	},
	{
		id: '1',
		componentType: types.WIDGET_CIRCLES_ANSWERS,
		question: 'Choose the widget you like:',
		answers: ['21', 'day', 'event', 'graphic', 'moon', 'steps', 'week'],
		selected: [],
		buttonText: 'Continue',
		skipable: false,
		isMultiple: true,
	},
	{
		id: '2',
		componentType: types.TEXT_CIRCLES_ANSWERS,
		question: 'Choose the widget font you like:',
		answers: [
			'Aleo',
			'Buenard',
			'Palatino',
			'Vidaloka',
			'Pacifico',
			'Lalezar',
			'Rakkas',
			'Cabin',
		],
		selected: [],
		buttonText: 'Continue',
		skipable: false,
		isMultiple: true,
	},
]

export const secondFlow = [
	{
		id: '0',
		componentType: types.NOTIFICATION_ANSWERS,
		question: 'Hi there! Which font you prefer for this message:',
		answers: ['italic', 'cursive', 'bold'],
		selected: [],
		buttonText: 'Continue',
		skipable: false,
		isMultiple: false,
	},
	{
		id: '1',
		componentType: types.TEXT_CIRCLES_ANSWERS,
		question: 'Choose keyboard font you like:',
		answers: [
			'Aleo',
			'Buenard',
			'Palatino',
			'Vidaloka',
			'Pacifico',
			'Lalezar',
			'Rakkas',
			'Cabin',
		],
		selected: [],
		buttonText: 'Continue',
		skipable: false,
		isMultiple: true,
	},
	{
		id: '2',
		componentType: types.WIDGET_SQUARE_ANSWERS,
		question: 'Choose unique keybord symbols you like:',
		answers: [1, 2, 3, 4, 5, 6],
		selected: [],
		buttonText: 'Continue',
		skipable: false,
		isMultiple: true,
		questionWidth: 375,
	},
	{
		id: '3',
		componentType: types.KEYBOARD_ANSWERS,
		question: 'Find your keyboard theme',
		answers: [1, 2, 3, 4, 5, 6],
		selected: -1,
		buttonText: 'Continue',
		skipable: false,
		isMultiple: false,
	},
]

export const thirdFlow = [
	{
		id: '0',
		componentType: types.SQUARE_THEMES,
		question: 'Hi there!\nFind your style',
		questionWidth: '220px',
		answers: ['Games', 'Neon', 'Pastel', 'Cartoons'],
		selected: '',
		buttonText: 'none',
		skipable: false,
		isMultiple: false,
	},
	{
		id: '1',
		componentType: types.RECTANGLE_THEMES,
		question: 'Do you like this theme?',
		answers: ['No', 'Yes'],
		selected: '',
		buttonText: 'none',
		skipable: false,
		isMultiple: false,
	},
	{
		id: '2',
		componentType: types.RECTANGLE_THEMES,
		question: 'Maybe this one?',
		answers: ['No', 'Yes'],
		selected: '',
		buttonText: 'none',
		skipable: false,
		isMultiple: false,
	},
	{
		id: '3',
		componentType: types.RECTANGLE_THEMES,
		question: 'Or this one?',
		answers: ['No', 'Yes'],
		selected: '',
		buttonText: 'none',
		skipable: false,
		isMultiple: false,
	},
]
