import * as queryString from 'query-string'
import { setSaleScreenABTestValue } from '../../../../redux/slices/config'
import { sendConfirmEmail, setEmail } from '../../../../redux/slices/userSlice'

export function QueryHandler(email, query, handlers) {
	const data = queryString.parse(query)

	if (data.modal === 'true') {
		handlers.setIsShownModal(true)
	}

	if (data.message === 'true') {
		handlers.dispatch(sendConfirmEmail())
	}

	if (data.salescreen_test) {
		handlers.dispatch(setSaleScreenABTestValue(data.salescreen_test))
	}

	if (data.email) {
		handlers.dispatch(
			setEmail({
				email: data.email,
			}),
		)
	} else if (!email) {
		handlers.history.push('/email')
	}
}

export const SendEmailRequest = body => {
	fetch(`https://fathomless-taiga-49278.herokuapp.com/api/sendios/email`, {
		method: 'POST',
		headers: {
			auth: '135017',
			'Content-Type': 'application/json',
		},
		body: JSON.stringify(body),
	})
}
