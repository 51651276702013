import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'

import { Button, Input } from '../../components/common'

import {
	getEmail,
	getIsAuthorized,
	getUserId,
	setEmail,
	setIsAuthorized,
	getIsOnlyLegalFlow,
} from '../../redux/slices/userSlice'
import { ampEvent } from '../../utils/amplitude'
import { getIsAlreadySubscribed } from '../../utils/getIsAlreadySubscribed'
import validateEmail from '../../utils/validateEmail'
import { paramsToQuery } from '../../utils/paramsToQuery'

import './styles.scss'

const ENTER_KEYCODE = 13

const AccountScreen = () => {
	const dispatch = useDispatch()
	const history = useHistory()
	const user_id = useSelector(getUserId)
	const isOnlyLegalFlow = useSelector(getIsOnlyLegalFlow)

	const isAuthorized = useSelector(getIsAuthorized)
	const email = useSelector(getEmail)
	const [formValue, setFormValue] = React.useState('')
	const [isFoundEmail, setIsFoundEmail] = React.useState(true)
	const [isSubmitButtonActive, setIsSubmitButtonActive] = React.useState(true)

	const isValidEmail = validateEmail(formValue) && isSubmitButtonActive

	const handleKeyboardSubmitEmail = e => {
		const { keyCode } = e
		if (keyCode === ENTER_KEYCODE && isValidEmail) {
			onSubmitEmail()
		}
	}

	const onSubmitEmail = async () => {
		setIsSubmitButtonActive(false)

		await getIsAlreadySubscribed(formValue)
			.then(response => response.json())
			.then(result => {
				if (result.premium) {
					setIsFoundEmail(true)
					dispatch(setEmail({ email: formValue }))
					dispatch(setIsAuthorized({ isAuthorized: true }))
					ampEvent('authorization_completed')
				} else {
					setIsFoundEmail(false)
					setIsSubmitButtonActive(true)
				}
			})
	}

	const cancelSubscription = () => {
		if (isOnlyLegalFlow) {
			history.push({
				pathname: `/unsubscribe/reason`,
				search: paramsToQuery({ user_id, email: email || formValue }),
			})
		} else {
			window.open(
				'https://mail.google.com/mail/?view=cm&fs=1&to=support@widget-box.me',
				'_blank',
			)
		}
	}

	return (
		<div className="account">
			{!isFoundEmail && (
				<div className="account__toast">
					<img
						src="/images/account/warning.png"
						alt="Warning sign."
						className="account__toast__image"
					/>
					<p className="account__toast__description">
						We can&apos;t find an account with this email address
					</p>
				</div>
			)}
			<h2 className="account__header">My Account</h2>
			{isAuthorized ? (
				<>
					<p className="account__title">Subscription</p>
					<p className="account__cancel">
						Your subscription is active but you may cancel it
					</p>
					<Button
						buttonText="Cancel subscription"
						onClick={cancelSubscription}
					/>
				</>
			) : (
				<>
					<p className="account__description">
						Enter Your email which you
						<br /> used on this website
					</p>
					<Input
						formValue={formValue}
						setFormValue={setFormValue}
						handleKeyboardSubmitEmail={handleKeyboardSubmitEmail}
					/>
					<Button
						buttonText="Continue"
						onClick={onSubmitEmail}
						disabled={!isValidEmail}
					/>
					<a
						href="https://mail.google.com/mail/u/0/?fs=1&to=support@widget-box.me&tf=cm&su=Forgotten Email"
						className="account__link"
						target="_blank"
						rel="noreferrer"
					>
						Have you forgotten email?
					</a>
				</>
			)}
		</div>
	)
}

export { AccountScreen }
