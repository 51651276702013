import { createSlice } from '@reduxjs/toolkit'
import {
	firstFlow,
	secondFlow,
	thirdFlow,
} from '../../initialStates/questions/index'

export const questionsSlice = createSlice({
	name: 'questions',
	initialState: firstFlow,
	reducers: {
		startQuiz: () => {
			return firstFlow
		},
		answerQuestionSingle: (state, action) => {
			const { questionId, answerIndex } = action.payload
			state[questionId].selected = answerIndex
		},
		answerQuestionMultiple: (state, action) => {
			const { questionId, selectedAnswers } = action.payload
			state[questionId].selected = selectedAnswers
		},
		setFirstFlow: () => {
			return firstFlow
		},
		setSecondFlow: () => {
			return secondFlow
		},
		setThirdFlow: () => {
			return thirdFlow
		},
	},
})

export const {
	answerQuestionMultiple,
	answerQuestionSingle,
	startQuiz,
	setSecondFlow,
	setFirstFlow,
	setThirdFlow,
} = questionsSlice.actions

export const getQuestions = state => state.questions
export const getQuestionsCount = state => state.questions.length

export default questionsSlice.reducer
