import React from 'react'

import './styles.scss'

const Step = ({ stepNumber }) => {
	return (
		<div className="step">
			<span className="step-content">Step {stepNumber}</span>
		</div>
	)
}

export { Step }
