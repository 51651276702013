export const merchantDataHandler = res => {
	return {
		merchantData: { ...res.merchantData },
		formParams: {
			submitButtonText: 'Get My Widgets',
			formTypeClass: 'default',
		},
		styles: {
			submit_button: {
				'background-color': '#FA3269!important',
				':disabled': {
					'background-color': '#FFD1DD!important',
				},
			},
		},
	}
}
