import React from 'react'

import './styles.scss'

const SquareAnswer = ({ answer, onSelectAnswer }) => {
	const [isSelected, setIsSelected] = React.useState(false)

	return (
		<img
			src={`/images/questions/squareAnswers/${answer}.png`}
			alt="Widget example."
			className={`square-answer ${isSelected ? 'selected' : ''}`}
			onClick={() => {
				setIsSelected(!isSelected)
				onSelectAnswer()
			}}
		/>
	)
}

export { SquareAnswer }
