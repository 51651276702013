import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router'

import { QuestionInfo } from '../../components/questions/common'
import {
	SquareAnswers,
	CircleAnswers,
	NotificationAnswers,
	KeyboardAnswers,
	SquareThemes,
	RectangleTheme,
} from '../../components/questions'

import { types } from '../../redux/initialStates/questions/questionTypes'
import { getQuestions } from '../../redux/slices/questionsSlice'
import {
	ampEvent,
	ampEventWithEventProperty,
	ampSetUserProperty,
} from '../../utils/amplitude'

import './styles.scss'
import { paramsToQuery } from '../../utils/paramsToQuery'
import { getFlow, getUserId } from '../../redux/slices/userSlice'
import { flowTypes } from '../../utils/flowTypes'
const VIEVPORT_RESIZE_TO_PX = 0.01

const QuestionsScreen = () => {
	const { id } = useParams()
	const history = useHistory()
	const questions = useSelector(getQuestions)
	const user_id = useSelector(getUserId)
	const flow = useSelector(getFlow)

	const activeQuestion = questions[id - 1]
	const [out, setOut] = useState(false)

	let vh

	const onWindowResize = () => {
		vh = window.innerHeight * VIEVPORT_RESIZE_TO_PX
		document.documentElement.style.setProperty('--vh', `${vh}px`)
	}

	useEffect(() => {
		if (id === '1') {
			setTimeout(() => {
				ampSetUserProperty('origin', window.location.origin)
				ampEvent('Onboarding_shown')
			}, 0)
		}
		onWindowResize()
		window.addEventListener('resize', onWindowResize)
	}, [])

	const nextQuestion = () => {
		ampEventWithEventProperty('Question_answered', { question: +id })

		const isLastQuestion = +id === questions.length

		if (!isLastQuestion) {
			if (flow === flowTypes.THEMES) {
				setOut(true)
			}
			setTimeout(() => {
				history.push({
					pathname: `/questions/${+id + 1}`,
					search: paramsToQuery({ user_id }),
				})
				if (flow === flowTypes.THEMES) {
					setOut(false)
				}
			}, 400)
			return
		}

		history.push({
			pathname: `/processing`,
			search: paramsToQuery({ user_id }),
		})
	}

	const questionsController = () => {
		switch (activeQuestion.componentType) {
			case types.SQUARE_ANSWERS: {
				return (
					<SquareAnswers
						question={activeQuestion}
						nextQuestion={nextQuestion}
					/>
				)
			}
			case types.WIDGET_CIRCLES_ANSWERS: {
				return (
					<CircleAnswers
						question={activeQuestion}
						nextQuestion={nextQuestion}
					/>
				)
			}
			case types.TEXT_CIRCLES_ANSWERS: {
				return (
					<CircleAnswers
						question={activeQuestion}
						nextQuestion={nextQuestion}
					/>
				)
			}
			case types.NOTIFICATION_ANSWERS: {
				return (
					<NotificationAnswers
						question={activeQuestion}
						nextQuestion={nextQuestion}
					/>
				)
			}
			case types.WIDGET_SQUARE_ANSWERS: {
				return (
					<SquareAnswers
						question={activeQuestion}
						nextQuestion={nextQuestion}
					/>
				)
			}
			case types.KEYBOARD_ANSWERS: {
				return (
					<KeyboardAnswers
						question={activeQuestion}
						nextQuestion={nextQuestion}
					/>
				)
			}
			case types.SQUARE_THEMES: {
				return (
					<SquareThemes question={activeQuestion} nextQuestion={nextQuestion} />
				)
			}
			case types.RECTANGLE_THEMES: {
				return (
					<RectangleTheme
						question={activeQuestion}
						nextQuestion={nextQuestion}
					/>
				)
			}
			default: {
				return <p>Component now found</p>
			}
		}
	}

	return (
		<div
			className={`questions ${
				flow === flowTypes.THEMES ? (out ? 'out' : 'in') : ''
			}`}
		>
			<QuestionInfo
				id={id}
				question={activeQuestion.question}
				width={activeQuestion.questionWidth}
			/>
			<div className="questions-answers">{questionsController()}</div>
		</div>
	)
}

export { QuestionsScreen }
