import React from 'react'

import { Button } from '../../common'
import { flowTypes } from '../../../utils/flowTypes'

import './styles.scss'

const Main = ({ flow, saleFlow, title, text, image, onClick }) => {
	const renderMainTitle = () => {
		switch (flow) {
			case flowTypes.LEGAL: {
				return 'What else can you do with Widgetbox App'
			}
			case flowTypes.DEFAULT: {
				if (saleFlow === 'original') {
					return 'What else could be done with Widgetbox:'
				} else {
					return 'What else can you do with Widgetbox App'
				}
			}
			case flowTypes.KEYBOARD: {
				return 'What else could be done with Widgetbox:'
			}
			default: {
				return <p>Component not found</p>
			}
		}
	}

	const titleStyle = {
		margin:
			flow === flowTypes.LEGAL && saleFlow === 'original'
				? '104px 0px 0px'
				: '',
	}

	return (
		<div className="main">
			<div
				className={`main-title ${flow === flowTypes.KEYBOARD ? 'violet' : ''}`}
			>
				{saleFlow === 'original' && flow === flowTypes.DEFAULT
					? 'Your unique home screen is ready!'
					: `${title}`}
			</div>
			<img
				src={`/images/sale/${image}-frame-back${
					(flow === flowTypes.LEGAL || flow === flowTypes.DEFAULT) &&
					saleFlow === 'original'
						? '-a'
						: ''
				}.png`}
				alt="Phone frame."
				className="main-frame"
			/>
			<div className="main-result">
				{(flow === flowTypes.KEYBOARD || saleFlow === 'original') && (
					<img
						src="/images/sale/lock.png"
						alt="Lock."
						className="main-result-lock"
					/>
				)}
				{(saleFlow === 'original' || flow === flowTypes.KEYBOARD) && (
					<span className="main-result-text">{text}</span>
				)}
				{(flow === flowTypes.KEYBOARD || saleFlow === 'original') && (
					<Button buttonText="Unlock Result" onClick={onClick} />
				)}
			</div>
			<div className="main-border"></div>
			{(flow === flowTypes.LEGAL || flow === flowTypes.DEFAULT) && (
				<div className="main-winner" style={titleStyle}>
					{saleFlow === 'original' && flow === flowTypes.DEFAULT && (
						<img
							src="/images/sale/apple-winner.png"
							alt="Apple. Best of apps winner."
							className="main-winner-champion"
						/>
					)}
					<p className="main-winner-label">
						{renderMainTitle()}{' '}
						{(flow === flowTypes.LEGAL ||
							(flow === flowTypes.DEFAULT && saleFlow === 'test')) && (
							<img
								src="/images/common/widget-box-logo.png"
								alt="Logo"
								className="main-winner-award"
							/>
						)}
					</p>
				</div>
			)}
		</div>
	)
}

export { Main }
