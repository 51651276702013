export const onlyLegalFlowCountry = ['UA']

export const europeanCountries = [
	'AU',
	'AT',
	'BE',
	'HR',
	'CZ',
	'DK',
	'FR',
	'DE',
	'IE',
	'IL',
	'IT',
	'LU',
	'NL',
	'NZ',
	'NO',
	'PL',
	'ES',
	'SE',
	'GB',
	'US',
	'CA',
	'UA',
]
