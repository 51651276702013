import * as queryString from 'query-string'
import { sendConfirmEmail, setEmail } from '../../../redux/slices/userSlice'

export function QueryHandler(email, query, handlers) {
	const data = queryString.parse(query)

	if (data.modal === 'true') {
		handlers.setIsShownModal(true)
	}

	if (data.message === 'true') {
		handlers.dispatch(sendConfirmEmail())
	}

	if (data.email) {
		handlers.dispatch(
			setEmail({
				email: data.email,
			}),
		)
	}
}
