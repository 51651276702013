const unsubscribeFetch = body =>
	fetch(
		`${process.env.REACT_APP_SERVER_URL}/api/solid/subscriptions/cancel/${process.env.REACT_APP_PROJECT_NAME}/${body.email}`,
		{
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
		},
	)

export default unsubscribeFetch
