import React from 'react'
import { useSelector } from 'react-redux'

import './styles.scss'

import { getOfferTime } from '../../../redux/slices/userSlice'

const SECONDS_IN_MINUTE = 60
const SECONDS_FOR_UP = 10

const Timer = ({ margin }) => {
	const offerTime = useSelector(getOfferTime)

	const fromDexToTime = time => {
		if (time > 0) {
			let minutes = parseInt(time / SECONDS_IN_MINUTE, SECONDS_FOR_UP)
			let seconds = parseInt(time % SECONDS_IN_MINUTE, SECONDS_FOR_UP)
			minutes = minutes < SECONDS_FOR_UP ? '0' + minutes : minutes
			seconds = seconds < SECONDS_FOR_UP ? '0' + seconds : seconds
			return minutes + ':' + seconds
		} else {
			return false
		}
	}

	return (
		<div className="timer-container" style={{ marginTop: margin }}>
			<div className="timer-container__wrapper">
				<div className="timer-container__offer">
					<p className="timer-container__title"> Limited time offer</p>
					<p className="timer-container__time">
						{fromDexToTime(offerTime) || '00:00'}
					</p>
					<p className="timer-container__info">minutes seconds</p>
				</div>
			</div>
		</div>
	)
}

export { Timer }
