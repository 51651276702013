import React from 'react'

import './styles.scss'

const AnswerNotification = ({ answer, onSelectAnswer }) => {
	const [isSelected, setIsSelected] = React.useState(false)

	return (
		<div
			className={`notification ${isSelected ? 'selected' : ''}`}
			onClick={() => {
				setIsSelected(!isSelected)
				onSelectAnswer()
			}}
		>
			<img
				src={`/images/questions/notificationAnswers/${answer}${
					isSelected ? '-active' : ''
				}.png`}
				alt={answer}
				className="notification__image"
			/>
		</div>
	)
}

export { AnswerNotification }
