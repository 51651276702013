import React from 'react'

import './styles.scss'

const AnswerWidget = ({ imageWidth, questionId, answer, onSelectAnswer }) => {
	const [isSelected, setIsSelected] = React.useState(false)

	return (
		<div
			className={`widget ${isSelected ? 'selected' : ''}`}
			onClick={() => {
				setIsSelected(!isSelected)
				onSelectAnswer()
			}}
		>
			<img
				src={`/images/questions/widgetSquareAnswers/${questionId}/${answer}.png`}
				alt={answer}
				className="widget__image"
				style={{ width: imageWidth }}
			/>
		</div>
	)
}

export { AnswerWidget }
