import React from 'react'
import { useSelector } from 'react-redux'
import { Redirect, useLocation } from 'react-router'
import * as queryString from 'query-string'

import { Step } from '../../components/success'

import './styles.scss'
import { getBill, getLifetimeBill } from '../../redux/slices/userSlice'

const SuccessScreen = () => {
	const { search } = useLocation()

	const bill = useSelector(getBill)
	const lifetimeBill = useSelector(getLifetimeBill)

	const data = queryString.parse(search)

	const isBilled = bill || lifetimeBill || data.billed

	React.useEffect(() => {
		document.getElementById('success')?.scrollIntoView()
	}, [])

	return isBilled ? (
		<div className="success" id="success">
			<h2 className="success-title">Success!</h2>
			<span className="success-label">
				You&apos;ve got premium access to app. Download right now!
			</span>
			<div className="success-border"></div>
			<div className="success-instruction">
				<h2 className="success-instruction-title">Instructions</h2>
				<Step stepNumber={1} />
				<span className="success-instruction-label">
					Follow by the link to get Widgetbox app
				</span>
				<div className="success-instruction-images">
					<a
						href="https://apps.apple.com/US/app/id1527561558?mt=8"
						className="link"
						target="_blank"
						rel="noreferrer"
					>
						<img
							src="/images/success/download-app-store.png"
							alt="Download on the App Store."
						/>
					</a>
				</div>
			</div>
			<div className="success-instruction">
				<Step stepNumber={2} />
				<span className="success-instruction-label">
					Next step is your activation key
				</span>
				<img
					src="/images/success/step2.png"
					alt="Instruction."
					className="success-instruction-email"
				/>
			</div>
		</div>
	) : (
		<Redirect to="/" />
	)
}

export { SuccessScreen }
