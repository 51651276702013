import React from 'react'

import './styles.scss'

const Button = ({ buttonText, onClick, disabled = false }) => {
	return (
		<button
			className={`button ${disabled ? 'button-disabled' : ''}`}
			disabled={disabled}
			onClick={onClick}
		>
			{buttonText}
		</button>
	)
}

export { Button }
