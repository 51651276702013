export const productNamesByProductId = {
	[process.env.REACT_APP_1WEEK_PRODUCT_ID]:
		'v.2.1 WidgetBox weekly 5.99, initial 0.99',
	[process.env.REACT_APP_1MONTH_PRODUCT_ID]:
		'v2.0 Widgetbox Initial_3d - 1 month',
	[process.env.REACT_APP_3MONTH_PRODUCT_ID]:
		'v2.0 Widget Box Initial_3d - 3 month',
	[process.env.REACT_APP_DISCOUNT_PRODUCT_ID]: 'v.1.0 Widget Box discount',
	[process.env.REACT_APP_PRODUCT_ID]: 'v.1.0 Widget Box',
	[process.env.REACT_APP_UPSELL_1_PRODUCT_ID]: 'Upsell 1 WidgetBox',
	[process.env.REACT_APP_UPSELL_2_PRODUCT_ID]: 'Upsell 2 WidgetBox',
	[process.env.REACT_APP_LIFETIME_UNSUBSCRIBE_PRODUCT_ID]:
		'Lifetime unsubscribe WidgetBox',
}
