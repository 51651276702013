export const widgetCirclesStyles = [
	// 21
	{
		width: 87.13,
		height: 87.29,
		imageWidth: 71.64,
		imageHeight: 71.64,
		left: 135,
		top: 70,
		animationName: 'widget1-flow',
		animationDuration: '1s',
	},
	// day
	{
		width: 123.92,
		height: 124.14,
		imageWidth: 101.89,
		imageHeight: 102.07,
		left: 13,
		top: 105,
		animationName: 'widget2-flow',
		animationDuration: '1s',
	},
	// event
	{
		width: 87.13,
		height: 87.29,
		imageWidth: 71.64,
		imageHeight: 71.77,
		left: 150,
		top: 165,
		animationName: 'widget3-flow',
		animationDuration: '1s',
	},
	// graphic
	{
		width: 87.13,
		height: 87.29,
		imageWidth: 71.64,
		imageHeight: 71.77,
		left: 75,
		top: 230,
		animationName: 'widget4-flow',
		animationDuration: '1s',
	},
	// moon
	{
		width: 87.13,
		height: 87.29,
		imageWidth: 71.64,
		imageHeight: 71.77,
		left: 240,
		top: 210,
		animationName: 'widget5-flow',
		animationDuration: '1s',
	},
	// steps
	{
		width: 123.92,
		height: 124.14,
		imageWidth: 101.89,
		imageHeight: 102.07,
		left: 230,
		top: 70,
		animationName: 'widget6-flow',
		animationDuration: '1s',
	},
	// week
	{
		width: 78.42,
		height: 78.56,
		imageWidth: 64.48,
		imageHeight: 64.59,
		left: 195,
		top: 0,
		animationName: 'widget7-flow',
		animationDuration: '1s',
	},
]

export const textCirclesStyles = [
	// Aleo
	{
		width: 108.9,
		height: 108.9,
		imageWidth: 48,
		imageHeight: 24,
		left: 40,
		top: 0,
		animationName: 'textBubble1-flow',
		animationDuration: '1s',
	},
	// buenard
	{
		width: 79,
		height: 79,
		imageWidth: 58,
		imageHeight: 18,
		left: 160,
		top: 0,
		animationName: 'textBubble2-flow',
		animationDuration: '1s',
	},
	// Palatino
	{
		width: 85,
		height: 85,
		imageWidth: 59,
		imageHeight: 18,
		left: 250,
		top: 5,
		animationName: 'textBubble3-flow',
		animationDuration: '1s',
	},
	// Vidaloka
	{
		width: 98,
		height: 98,
		imageWidth: 68,
		imageHeight: 22,
		left: 25,
		top: 120,
		animationName: 'textBubble4-flow',
		animationDuration: '1s',
	},
	// Pacifico
	{
		width: 106,
		height: 106,
		imageWidth: 65,
		imageHeight: 35,
		left: 130,
		top: 85,
		animationName: 'textBubble5-flow',
		animationDuration: '1s',
	},
	// Lalezar
	{
		width: 100,
		height: 100,
		imageWidth: 62,
		imageHeight: 26,
		left: 245,
		top: 100,
		animationName: 'textBubble6-flow',
		animationDuration: '1s',
	},
	// Rakkas
	{
		width: 100,
		height: 100,
		imageWidth: 60,
		imageHeight: 30,
		left: 100,
		top: 200,
		animationName: 'textBubble7-flow',
		animationDuration: '1s',
	},
	// Cabin
	{
		width: 79,
		height: 79,
		imageWidth: 58,
		imageHeight: 18,
		left: 205,
		top: 195,
		animationName: 'textBubble8-flow',
		animationDuration: '1s',
	},
]
