import React from 'react'

import { Button } from '../../common'

import './styles.scss'

const HorizontalFooter = ({ onClick }) => {
	return (
		<div className="horizontal-footer">
			<Button buttonText="Continue" onClick={onClick} />
		</div>
	)
}

export { HorizontalFooter }
