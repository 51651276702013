import React from 'react'

import './styles.scss'

const AnswerImage = ({ questionId, answer, isActive, onSelectAnswer }) => {
	return (
		<div
			className={`answer-image ${isActive ? 'selected' : ''}`}
			onClick={onSelectAnswer}
		>
			<img
				src={`/images/questions/widgetSquareAnswers/${questionId}/${answer}.png`}
				alt={answer}
				className="answer-image__image"
			/>
		</div>
	)
}

export { AnswerImage }
