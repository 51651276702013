import { flowTypes } from '../../../utils/flowTypes'

const user = {
	flow: flowTypes.LEGAL,
	email: '',
	isAuthorized: false,
	payPalToken: '',
	cardToken: '',
	error: '',
	fbp: '',
	fbc: '',
	firstUpsale: false,
	secondUpsale: false,
	bill: false,
	total: 0,
	annualPlan: 0,
	discountPrice: 0,
	isSendConfirmEmail: false,
	isSendSuccessEmail: false,
	discount: '',
	unsubscribeReason: '',
	isUnsubscribeShown: '',
	productId: undefined,
	activeOfferId: 1,
	errorPath: '',
	lifetimeBill: false,
	user_id: '',
	isOnlyLegalFlow: false,
	saleFlow: '',
	countryCode: '',
	currentOffer: {},
	selectedTheme: 'neon',
	offerTime: 600,
	isStoppedOfferTimer: false,
	saleScreenTag: 1,
}

export default user
