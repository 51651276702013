export const payPalRecurentRequest = async body => {
	const data = await fetch(
		`${process.env.REACT_APP_SERVER_URL}/api/paypal/${process.env.REACT_APP_PROJECT_NAME}/recurent`,
		{
			method: 'POST',
			headers: {
				'Content-type': 'application/json',
				auth: process.env.REACT_APP_PAYPAL_PUBLIC_KEY,
			},
			body: JSON.stringify(body),
		},
	)
	return data
}

export const cardRecurentRequest = async body => {
	const data = await fetch(
		`${process.env.REACT_APP_SERVER_URL}/api/card/${process.env.REACT_APP_PROJECT_NAME}/recurent`,
		{
			method: 'POST',
			headers: {
				'Content-type': 'application/json',
				auth: process.env.REACT_APP_CARD_PUBLIC_KEY,
			},
			body: JSON.stringify(body),
		},
	)
	return data
}

export const payPalSubscriptionRecurentRequest = async body => {
	const data = await fetch(
		`${process.env.REACT_APP_SERVER_URL}/api/paypal/${process.env.REACT_APP_PROJECT_NAME}/subscription/recurent`,
		{
			method: 'POST',
			headers: {
				'Content-type': 'application/json',
				auth: process.env.REACT_APP_PAYPAL_PUBLIC_KEY,
			},
			body: JSON.stringify(body),
		},
	)
	return data
}

export const cardSubscriptionRecurentRequest = async body => {
	const data = await fetch(
		`${process.env.REACT_APP_SERVER_URL}/api/card/${process.env.REACT_APP_PROJECT_NAME}/subscription/recurent`,
		{
			method: 'POST',
			headers: {
				'Content-type': 'application/json',
				auth: process.env.REACT_APP_CARD_PUBLIC_KEY,
			},
			body: JSON.stringify(body),
		},
	)
	return data
}
