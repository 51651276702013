import React from 'react'

import { Button } from '../../common'

import { ampEvent } from '../../../utils/amplitude'

import './styles.scss'

const Main = ({ setIsShownModal }) => {
	const handleBuySubscription = () => {
		setIsShownModal(true)
		setTimeout(() => {
			ampEvent('Checkout_shown')
		}, 0)
		document.body.style.overflow = 'hidden'
	}

	return (
		<div className="discount-main">
			<img
				src="/images/discount/price.png"
				alt="Special offer."
				className="discount-main__image"
			/>
			<p className="discount-main__label">
				WidgetBox lets you add stylish widgets directly onto your home screen!
				<br />
				Choose from premade widget designs, or better yet, make your own.
			</p>
			<Button buttonText="Get my Discount" onClick={handleBuySubscription} />
			<span className="discount-main__price">
				No charge for 3 days
				<br />
				Cancel anytime · $6.99/weekly
			</span>
		</div>
	)
}

export { Main }
