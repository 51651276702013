import React from 'react'
import { useDispatch } from 'react-redux'

import { widgetCirclesStyles, textCirclesStyles } from './common'

import { AnswerCircle } from '../common'
import { Button } from '../../common'
import { types } from '../../../redux/initialStates/questions/questionTypes'
import { answerQuestionMultiple } from '../../../redux/slices/questionsSlice'

import './styles.scss'

const CircleAnswers = ({ question, nextQuestion }) => {
	const dispatch = useDispatch()

	const [selectedAnswers, setSelectedAnswers] = React.useState([])

	const onSelectAnswer = index => {
		const isAlreadySelected = selectedAnswers.some(
			answerIndex => answerIndex === index,
		)
		if (isAlreadySelected) {
			setSelectedAnswers(
				selectedAnswers.filter(answerIndex => answerIndex !== index),
			)
			return
		}
		setSelectedAnswers(
			[...selectedAnswers, index].sort((a, b) => (a > b ? 1 : -1)),
		)
	}

	const circlesController = () => {
		switch (question.componentType) {
			case types.WIDGET_CIRCLES_ANSWERS: {
				return (
					<>
						{question.answers.map((answer, answerIndex) => (
							<AnswerCircle
								key={answer + answerIndex}
								widget={answer}
								circleStyle={widgetCirclesStyles[answerIndex]}
								onSelectAnswer={() => onSelectAnswer(answerIndex)}
							/>
						))}
					</>
				)
			}
			case types.TEXT_CIRCLES_ANSWERS: {
				return (
					<>
						{question.answers.map((answer, answerIndex) => (
							<AnswerCircle
								key={answer + answerIndex}
								font={answer}
								circleStyle={textCirclesStyles[answerIndex]}
								onSelectAnswer={() => onSelectAnswer(answerIndex)}
							/>
						))}
					</>
				)
			}
			default: {
				return <p>Component now found</p>
			}
		}
	}

	const onAnswerQuestion = () => {
		dispatch(
			answerQuestionMultiple({ questionId: question.id, selectedAnswers }),
		)
		setSelectedAnswers([])
		nextQuestion()
	}

	return (
		<div className="circles-answers">
			<div className="circles-answer-wrapper">{circlesController()}</div>
			<Button
				buttonText={question.buttonText}
				onClick={onAnswerQuestion}
				disabled={!selectedAnswers.length}
			/>
		</div>
	)
}

export { CircleAnswers }
