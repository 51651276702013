import React from 'react'

import { Swiper, SwiperSlide } from 'swiper/react'
import { Button } from '../../common'

import './styles.scss'

const ReviewsSlider = ({
	theme,
	title,
	button,
	buttonText,
	onClick,
	saleFlow,
}) => {
	let padding
	let titleMarginBottom

	if (saleFlow === 'original') {
		padding = 0
		titleMarginBottom = 10
	} else {
		padding = 69
		titleMarginBottom = 0
	}

	const renderSlider = () => {
		switch (theme) {
			case 'neon': {
				return (
					<>
						<SwiperSlide>
							<img
								className="sale-slider-slide"
								src="/images/sale/neon-review.png"
								alt="review1"
							/>
						</SwiperSlide>
						<SwiperSlide>
							<img
								className="sale-slider-slide"
								src="/images/sale/games-review.png"
								alt="review2"
							/>
						</SwiperSlide>
						<SwiperSlide>
							<img
								className="sale-slider-slide"
								src="/images/sale/pastel-review.png"
								alt="review3"
							/>
						</SwiperSlide>
					</>
				)
			}
			case 'pastel': {
				return (
					<>
						<SwiperSlide>
							<img
								className="sale-slider-slide"
								src="/images/sale/pastel-review.png"
								alt="review1"
							/>
						</SwiperSlide>
						<SwiperSlide>
							<img
								className="sale-slider-slide"
								src="/images/sale/neon-review.png"
								alt="review2"
							/>
						</SwiperSlide>
						<SwiperSlide>
							<img
								className="sale-slider-slide"
								src="/images/sale/games-review.png"
								alt="review3"
							/>
						</SwiperSlide>
					</>
				)
			}
			case 'games': {
				return (
					<>
						<SwiperSlide>
							<img
								className="sale-slider-slide"
								src="/images/sale/games-review.png"
								alt="review1"
							/>
						</SwiperSlide>
						<SwiperSlide>
							<img
								className="sale-slider-slide"
								src="/images/sale/pastel-review.png"
								alt="review2"
							/>
						</SwiperSlide>
						<SwiperSlide>
							<img
								className="sale-slider-slide"
								src="/images/sale/neon-review.png"
								alt="review3"
							/>
						</SwiperSlide>
					</>
				)
			}
			case 'cartoons': {
				return (
					<>
						<SwiperSlide>
							<img
								className="sale-slider-slide"
								src="/images/sale/games-review.png"
								alt="review1"
							/>
						</SwiperSlide>
						<SwiperSlide>
							<img
								className="sale-slider-slide"
								src="/images/sale/pastel-review.png"
								alt="review1"
							/>
						</SwiperSlide>
						<SwiperSlide>
							<img
								className="sale-slider-slide"
								src="/images/sale/neon-review.png"
								alt="review1"
							/>
						</SwiperSlide>
					</>
				)
			}
		}
	}

	return (
		<>
			<div className="title-container" style={{ paddingTop: padding }}>
				<h1 className="title" style={{ marginBottom: titleMarginBottom }}>
					{title}
				</h1>
			</div>
			<Swiper
				slidesPerView={2}
				spaceBetween={20}
				speed={1500}
				centeredSlides={true}
				pagination={{
					clickable: true,
				}}
				autoplay={false}
				className="slider"
				style={{ marginBottom: saleFlow === 'original' ? 90 : 0 }}
			>
				{renderSlider()}
			</Swiper>

			{button && <Button buttonText={buttonText} onClick={onClick} />}
		</>
	)
}

export { ReviewsSlider }
