import React, { useState } from 'react'

import './styles.scss'

const SquareTheme = ({ answer, onSelectAnswer }) => {
	const [isSelected, setIsSelected] = useState(false)

	return (
		<div
			className={`container ${isSelected ? 'selected' : ''}`}
			onClick={() => {
				setIsSelected(!isSelected)
				onSelectAnswer()
			}}
		>
			<img
				src={`/images/questions/squareThemes/${answer}.svg`}
				alt={answer}
				className="container-answer"
			/>
			<span className="container-text">{answer}</span>
		</div>
	)
}

export { SquareTheme }
