import React from 'react'
import lottie from 'lottie-web'

import './styles.scss'

const Header = () => {
	const animationRef = React.useRef()

	React.useEffect(() => {
		lottie.loadAnimation({
			container: animationRef.current,
			renderer: 'svg',
			loop: true,
			autoplay: true,
			animationData: require('./animation.json'),
		})
	}, [])

	return (
		<div className="discount-header">
			<div className="discount-header__animation" ref={animationRef}></div>
			<h2 className="discount-header__title">Nobody has it but you do!</h2>
		</div>
	)
}

export { Header }
