import React from 'react'

import { CustomizeHomeProgressbar } from '../../components/processing'
import { ampEvent } from '../../utils/amplitude'
import './styles.scss'

const ProcessingScreen = () => {
	React.useEffect(() => {
		setTimeout(() => {
			ampEvent('Widget_processing')
		}, 0)
	}, [])

	return (
		<div className="processing">
			<CustomizeHomeProgressbar />
		</div>
	)
}

export { ProcessingScreen }
