import React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'

import QuestionsScreen from './screens/questions'
import ProcessingScreen from './screens/processing'
import EmailScreen from './screens/email'
import SaleScreen from './screens/sale'
import ErrorScreen from './screens/error'
import SuccessScreen from './screens/success'
import DiscountScreen from './screens/discount'
import AccountScreen from './screens/account'
import UpsaleScreen from './screens/upsale'
import UnsubscribeScreen from './screens/unsubscribe/container'
import { ProtectedRoute } from './components/common/hoc'
import PDFViewer from './components/common/PDFViewer'

const Router = () => {
	return (
		<Switch>
			<Route path="/questions/:id" component={QuestionsScreen} />
			<Route path="/processing" component={ProcessingScreen} />
			<Route path="/email" component={EmailScreen} />
			<Route path="/sale" component={SaleScreen} />
			<Route path="/sale11" component={SaleScreen} />
			<Route path="/sale22" component={SaleScreen} />
			<Route path="/sale33" component={SaleScreen} />
			<Route path="/discount" component={DiscountScreen} />
			<Route path="/account" component={AccountScreen} />
			<Route path="/upsale1" component={UpsaleScreen} />
			<Route path="/upsale2" component={UpsaleScreen} />
			<Route path="/unsubscribe/:stage" component={UnsubscribeScreen} />
			<Route
				path="/privacy-policy"
				component={() => <PDFViewer url="/pdf/General Privacy Policy.pdf" />}
			/>
			<Route
				path="/terms-of-use"
				component={() => <PDFViewer url="/pdf/Terms of Use.pdf" />}
			/>
			<Route
				path="/subscription-terms"
				component={() => <PDFViewer url="/pdf/Subscription Terms.pdf" />}
			/>
			<Route
				path="/money-back-policy"
				component={() => <PDFViewer url="/pdf/Money-back Policy.pdf" />}
			/>
			<Route path="/faq" component={() => <PDFViewer url="/pdf/FAQ.pdf" />} />
			<Route path="/success" component={SuccessScreen} />
			<ProtectedRoute path="/error" bill={false} component={ErrorScreen} />
			<Redirect to="/questions/1" />
		</Switch>
	)
}

export default Router
