import React from 'react'
import { Button } from '../../../..'

import './styles.scss'

const Header = ({
	isShowBackButton = false,
	isOpenBurgerMenu,
	toggleBurgerMenu,
	onClickBackHandler,
	isShowTimer,
	timerValue,
	handleScrollToPrice,
}) => (
	<div
		className={`navbar-container ${
			isOpenBurgerMenu ? 'navbar-container--opened-burger' : ''
		}`}
	>
		{isShowTimer && (
			<div className="header-timer">
				<div className="timer">
					<span className="timer__title">Special offer expires in </span>
					<span className="timer__value">{timerValue || '00:00'}</span>
				</div>
				<div className="scroll-button">
					<Button buttonText="Try for free" onClick={handleScrollToPrice} />
				</div>
			</div>
		)}
		{!isShowTimer && (
			<div className="navbar">
				<div
					className={`back-button ${
						isShowBackButton ? '' : 'navbar-back-button--hide'
					}`}
					onClick={() => isShowBackButton && onClickBackHandler()}
				>
					<img
						src="/images/common/header/left-arrow.svg"
						alt="left-arrow"
						className="back-button__arrow"
					/>
					<p className="back-button__text">Back</p>
				</div>

				<div className="navbar-logo">
					<img src="/images/common/header/logo.png" alt="logo" />
					WidgetBox
				</div>
				<div
					className={`burger-icon ${
						isOpenBurgerMenu ? 'burger-icon--open' : ''
					}`}
					onClick={toggleBurgerMenu}
				>
					<img
						src={`/images/common/burgerMenu/rectangle.svg`}
						alt="menu-icon"
						className="burger-icon__line burger-icon__line--top"
					/>
					<img
						src={`/images/common/burgerMenu/rectangle.svg`}
						alt="menu-icon"
						className="burger-icon__line burger-icon__line--bottom"
					/>
				</div>
			</div>
		)}
	</div>
)

export default Header
