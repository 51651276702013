import React from 'react'

import './styles.scss'

const Header1 = () => {
	return (
		<div className="header1">
			<h2 className="header1__title">
				Get additional
				<br />
				<span className="accent">Disney</span> widget pack
			</h2>
			<p className="header1__text">
				12+ World famous Disney characters on your homescreen. Competible with
				any 69+ widget
			</p>
		</div>
	)
}

export { Header1 }
