import React, { useState } from 'react'
import Confetti from 'react-confetti'
import { useSelector } from 'react-redux'

import { Footer, Form } from '../../components/email'
import {
	getFlow,
	getUserId,
	getCountryCode,
} from '../../redux/slices/userSlice'

import { ampEvent } from '../../utils/amplitude'

import { europeanCountries } from '../../model'

import './styles.scss'

const VIEVPORT_RESIZE_TO_PX = 0.01

const EmailScreen = () => {
	const flow = useSelector(getFlow)
	const user_id = useSelector(getUserId)
	const countryCode = useSelector(getCountryCode)

	const [checkboxes, setCheckboxes] = React.useState([true, true])
	const [isAgree, setIsAgree] = React.useState(true)

	const [isShowConfeti, setIsShowConfeti] = useState(true)

	let vh

	const onWindowResize = () => {
		vh = window.innerHeight * VIEVPORT_RESIZE_TO_PX
		document.documentElement.style.setProperty('--vh', `${vh}px`)
	}

	const updateCheckboxStatus = id => {
		const newStatus = [...checkboxes]
		newStatus[id] = !checkboxes[id]
		setCheckboxes([...newStatus])
		if (newStatus[0] === true) {
			setIsAgree(true)
		} else {
			setIsAgree(false)
		}
	}

	React.useEffect(() => {
		setTimeout(() => {
			ampEvent('email_shown')
		}, 0)
		setIsShowConfeti(true)
		setTimeout(() => {
			setIsShowConfeti(false)
		}, 5000)
		onWindowResize()
		window.addEventListener('resize', onWindowResize)
	}, [])

	React.useEffect(() => {
		if (europeanCountries.includes(countryCode)) {
			setCheckboxes(
				checkboxes.map((checkbox, index) => {
					if (index === checkboxes.length - 1) {
						checkbox = false
					}
					return checkbox
				}),
			)
		}
	}, [countryCode])

	return (
		<div className="email">
			{isShowConfeti && <Confetti numberOfPieces={300} recycle={false} />}
			<Form isAgree={isAgree} user_id={user_id} />
			<Footer
				flow={flow}
				user_id={user_id}
				checkboxes={checkboxes}
				updateCheckboxStatus={updateCheckboxStatus}
			/>
		</div>
	)
}

export { EmailScreen }
