import React from 'react'

import './styles.scss'

const Review = ({ image, title, content }) => {
	return (
		<div className="review-wrapper">
			<div className="review">
				<div className="review-header">
					<div className="review-header-title">
						<img
							src={`/images/sale/${image}.png`}
							alt="5 stars Rating."
							className="review-header-title-rating"
						/>
						<span className="review-header-title-content">{title}</span>
					</div>
					<img
						src="/images/sale/apple-store.png"
						alt="App store icon."
						className="review-header-store"
					/>
				</div>
				<span className="review-content">{content}</span>
			</div>
		</div>
	)
}

export { Review }
