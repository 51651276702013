import React from 'react'

import './styles.scss'

const OfferPlan = ({
	onOfferClicked,
	isBestseller,
	isSelected,
	emoji,
	period,
	periodTime,
	pricePerPeriod,
	pricePerDayDollars,
	pricePerDayCoins,
}) => {
	return (
		<div
			className={`offer-plan__border ${isSelected ? 'active' : ''}`}
			onClick={onOfferClicked}
		>
			{isBestseller && <span className="bestseller">Bestseller</span>}
			<div className="offer-plan">
				<img
					src={`/images/common/emoji/${emoji}.png`}
					alt={emoji}
					className="offer-plan__emoji"
				/>
				<div className="offer-plan__period">
					<p className="offer-plan__period__time">
						{periodTime}-{period}
					</p>
					<p className="offer-plan__period__price">
						${pricePerPeriod} per {periodTime} {period.toLowerCase()}
					</p>
				</div>
				<div className="offer-plan__price">
					<img
						src="/images/sale/price-bg.png"
						alt="Price back."
						className="offer-plan__price__back"
					/>
					<div className="offer-plan__price__value">
						<p
							className={`offer-plan__price__value__currency ${
								isSelected ? 'active' : ''
							}`}
						>
							$
						</p>
						<span
							className={`offer-plan__price__value__accent ${
								isSelected ? 'active' : ''
							}`}
						>
							{pricePerDayDollars}
						</span>
						<div className="offer-plan__price__value__subtext">
							<p
								className={`offer-plan__price__value__subtext__coins ${
									isSelected ? 'active' : ''
								}`}
							>
								{pricePerDayCoins}
							</p>
							<p className="offer-plan__price__value__subtext__description">
								per day
							</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export { OfferPlan }
