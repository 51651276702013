import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Redirect, Route, useLocation } from 'react-router-dom'
import * as queryString from 'query-string'

import {
	getBill,
	setBill,
	getLifetimeBill,
} from '../../../../redux/slices/userSlice'

const ProtectedRoute = ({ component: Component, bill, ...rest }) => {
	const { search } = useLocation()
	const dispatch = useDispatch()
	const lifetimeBill = useSelector(getLifetimeBill)

	let isBill = false

	const data = queryString.parse(search)
	if (data.billed) {
		dispatch(setBill({ value: data.billed }))
		isBill = data.billed
	} else {
		isBill = useSelector(getBill)
	}

	if (bill) {
		return (
			<Route
				{...rest}
				render={props => {
					if (isBill || lifetimeBill) {
						return <Component {...rest} {...props} />
					} else {
						return <Redirect to="/email" />
					}
				}}
			/>
		)
	}

	return (
		<Route
			{...rest}
			render={props => {
				return <Component {...rest} {...props} />
			}}
		/>
	)
}

export { ProtectedRoute }
