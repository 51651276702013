import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import initialState from '../../initialStates/user'
import {
	ampEventWithEventProperty,
	ampSetUserProperty,
} from '../../../utils/amplitude'
import { sendSuccessEmail } from '../../../utils/sendSuccessEmail'
import { purchasePropertyFetch } from '../../../utils/analyticsRequests'
import { productNamesByProductId } from '../../../model/productNamesByProductId'

export const sendConfirmationEmail = createAsyncThunk(
	'user/sendEmail',
	async body => {
		const data = await fetch(
			`${process.env.REACT_APP_SERVER_URL}/api/sendios/email`,
			{
				method: 'POST',
				headers: {
					auth: '135017',
					'Content-Type': 'application/json',
				},
				body: JSON.stringify(body),
			},
		)
		return data.status
	},
)

export const userSlice = createSlice({
	name: 'user',
	initialState,
	reducers: {
		setSaleFlow: (state, action) => {
			if (state.isOnlyLegalFlow) {
				state.saleFlow = '0'
			} else {
				const { saleFlow } = action.payload
				state.saleFlow = saleFlow
			}
		},
		setEmail: (state, action) => {
			const { email } = action.payload
			state.email = email
		},
		setIsAuthorized: (state, action) => {
			const { isAuthorized } = action.payload
			state.isAuthorized = isAuthorized
		},
		setPayPalToken: (state, action) => {
			const { payPalToken } = action.payload
			state.payPalToken = payPalToken
		},
		setCardToken: (state, action) => {
			const { cardToken } = action.payload
			state.cardToken = cardToken
		},
		setError: (state, action) => {
			const { error } = action.payload
			state.error = error
		},
		setDiscount: (state, action) => {
			const { discount } = action.payload
			state.discount = discount
		},
		setUserId: (state, action) => {
			// state.user_id = action.payload.user_id
			state.user_id = window.user_id
			ampSetUserProperty('user_id', window.user_id)
		},
		setBill: (state, action) => {
			if (action.payload.lifetime) {
				if (!state.lifetimeBill) {
					purchasePropertyFetch(
						{
							type: 'cancel_lifetime',
							onetime_price: 9.99,
							onetime_id: process.env.REACT_APP_LIFETIME_UNSUBSCRIBE_PRODUCT_ID,
							onetime_name:
								productNamesByProductId[
									process.env.REACT_APP_LIFETIME_UNSUBSCRIBE_PRODUCT_ID
								],
							currency: 'USD',
							payment_type: action.payload.type,
						},
						window.user_id,
					)
					ampEventWithEventProperty('onetime_purchase', {
						type: 'cancel_lifetime',
						onetime_price: 9.99,
						onetime_id: process.env.REACT_APP_LIFETIME_UNSUBSCRIBE_PRODUCT_ID,
						onetime_name:
							productNamesByProductId[
								process.env.REACT_APP_LIFETIME_UNSUBSCRIBE_PRODUCT_ID
							],
						currency: 'USD',
						payment_type: action.payload.type,
					})
					if (action.payload.type !== 'UNTRACKED') {
						window.fbq('track', 'Purchase', {
							currency: 'USD',
							value: '35.00',
						})
					}
					sendSuccessEmail(state.email, state.user_id)
				}
				state.lifetimeBill = true
				state.isSendSuccessEmail = true
				return
			}
			if (action.payload.type !== 'UNTRACKED') {
				if (!state.bill) {
					console.log(state.currentOffer?.type)
					if (state.currentOffer?.type === 'subscription') {
						ampEventWithEventProperty('subscription_purchase', {
							initial_price: state.currentOffer?.initial_price,
							rebill_price: state.currentOffer?.rebill_price,
							trial: state.currentOffer?.trial_period,
							billing_period: state.currentOffer?.billing_period,
							subscription_id: state.currentOffer?.product_id,
							subscription_name:
								productNamesByProductId[state.currentOffer?.product_id],
							currency: 'USD',
							payment_type: action.payload.type,
						})
						purchasePropertyFetch(
							{
								initial_price: state.currentOffer?.initial_price,
								rebill_price: state.currentOffer?.rebill_price,
								trial: state.currentOffer?.trial_period,
								billing_period: state.currentOffer?.billing_period,
								subscription_id: state.currentOffer?.product_id,
								subscription_name:
									productNamesByProductId[state.currentOffer?.product_id],
								currency: 'USD',
								payment_type: action.payload.type,
							},
							window.user_id,
						)
					} else if (state.currentOffer?.type === 'one_time') {
						ampEventWithEventProperty('onetime_purchase', {
							type: 'lifetime',
							onetime_price: state.currentOffer?.price,
							onetime_id: state.productId,
							onetime_name: productNamesByProductId[state.productId],
							currency: state.paymentCurrency,
							payment_type: action.payload.type,
						})
						purchasePropertyFetch(
							{
								type: 'lifetime',
								onetime_price: state.currentOffer?.price,
								onetime_id: state.productId,
								onetime_name: productNamesByProductId[state.productId],
								currency: state.paymentCurrency,
								payment_type: action.payload.type,
							},
							window.user_id,
						)
					}
					window.fbq('track', 'Purchase', {
						currency: 'USD',
						value: '35.00',
					})
					sendSuccessEmail(state.email, state.user_id)
				}
			}
			state.total = state.annualPlan - state.discountPrice
			state.bill = true
			state.isSendSuccessEmail = true
		},
		setTotal: (state, action) => {
			const { total } = action.payload
			state.total = total
		},
		setAnnualPlan: (state, action) => {
			const { annualPlan } = action.payload
			state.annualPlan = annualPlan
		},
		setDiscountPrice: (state, action) => {
			const { discountPrice } = action.payload
			state.discountPrice = discountPrice
		},
		setProductId: (state, action) => {
			const { productId } = action.payload
			state.productId = productId
		},
		setActiveOfferId: (state, action) => {
			const { activeOfferId } = action.payload
			state.activeOfferId = activeOfferId
		},
		setFlow: (state, action) => {
			if (state.isOnlyLegalFlow) {
				state.flow = ''
			} else {
				const { flow } = action.payload
				state.flow = flow
			}
		},
		setIsOnlyLegalFlow: (state, action) => {
			const { isOnlyLegalFlow } = action.payload
			state.isOnlyLegalFlow = isOnlyLegalFlow
		},
		setFirstUpsale: state => {
			state.firstUpsale = true
		},
		setSecondUpsale: state => {
			state.secondUpsale = true
		},
		setFbp: (state, action) => {
			state.fbp = action.payload.fbp
		},
		setFbc: (state, action) => {
			state.fbc = action.payload.fbc
		},
		sendConfirmEmail: state => {
			state.isSendConfirmEmail = true
		},
		setUnsubscribeReason: (state, action) => {
			const { unsubscribeReason } = action.payload
			state.unsubscribeReason = unsubscribeReason
		},
		showUnsubscribe: (state, action) => {
			const { isUnsubscribeShown } = action.payload
			state.isUnsubscribeShown = isUnsubscribeShown
		},
		setErrorPath: (state, action) => {
			const { errorPath } = action.payload
			state.unsubscribePath = errorPath
		},
		setCountryCode: (state, action) => {
			const { countryCode } = action.payload
			state.countryCode = countryCode
		},
		setCurrentOffer: (state, action) => {
			const { currentOffer } = action.payload
			state.currentOffer = { ...currentOffer }
		},
		setSelectedTheme: (state, action) => {
			const { selectedTheme } = action.payload
			state.selectedTheme = selectedTheme
		},
		setOfferTime: (state, action) => {
			const { offerTime } = action.payload
			state.offerTime = offerTime
		},
		setIsStoppedOfferTimer: (state, action) => {
			const { isStoppedOfferTimer } = action.payload
			state.isStoppedOfferTimer = isStoppedOfferTimer
		},
		setSaleScreenTag: (state, action) => {
			const { saleScreenTag } = action.payload
			state.saleScreenTag = saleScreenTag
		},
	},
})

export const {
	setEmail,
	setIsAuthorized,
	setPayPalToken,
	setCardToken,
	setError,
	setDiscount,
	setFirstUpsale,
	setSecondUpsale,
	setFbp,
	setUserId,
	setFbc,
	setBill,
	setTotal,
	setAnnualPlan,
	setDiscountPrice,
	setProductId,
	setActiveOfferId,
	setFlow,
	sendConfirmEmail,
	setUnsubscribeReason,
	showUnsubscribe,
	setErrorPath,
	setSaleFlow,
	setIsOnlyLegalFlow,
	setCountryCode,
	setCurrentOffer,
	setSelectedTheme,
	setOfferTime,
	setIsStoppedOfferTimer,
	setSaleScreenTag,
} = userSlice.actions

export const getEmail = state => state.user.email
export const getIsAuthorized = state => state.user.isAuthorized
export const getError = state => state.user.error
export const getDiscount = state => state.user.discount
export const getPayPalToken = state => state.user.payPalToken
export const getCardToken = state => state.user.cardToken
export const getBill = state => state.user.bill
export const getTotal = state => state.user.total
export const getAnnualPlan = state => state.user.annualPlan
export const getDiscountPrice = state => state.user.discountPrice
export const getProductId = state => state.user.productId
export const getActiveOfferId = state => state.user.activeOfferId
export const getFlow = state => state.user.flow
export const getFirstUpsale = state => state.user.firstUpsale
export const getSecondUpsale = state => state.user.secondUpsale
export const getFbp = state => state.user.fbp
export const getFbc = state => state.user.fbc
export const getIsSendConfirmEmail = state => state.user.isSendConfirmEmail
export const getIsSendSuccessEmail = state => state.user.isSendSuccessEmail
export const getShownUnsubscribe = state => state.user.isUnsubscribeShown
export const getErrorPath = state => state.user.unsubscribePath
export const getLifetimeBill = state => state.user.lifetimeBill
export const getUserId = state => state.user.user_id
export const getSaleFlow = state => state.user.saleFlow
export const getIsOnlyLegalFlow = state => state.user.isOnlyLegalFlow
export const getCountryCode = state => state.user.countryCode
export const getCurrentOffer = state => state.user.currentOffer
export const getSelectedTheme = state => state.user.selectedTheme
export const getOfferTime = state => state.user.offerTime
export const getIsStoppedOfferTimer = state => state.user.isStoppedOfferTimer
export const getSaleScreenTag = state => state.user.saleScreenTag

export default userSlice.reducer
