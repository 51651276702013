import React from 'react'

import './styles.scss'

const Header2 = () => {
	return (
		<div className="header2">
			<h2 className="header2__title">
				Add <span className="accent">live wallpaper </span> pack to your plan
			</h2>
			<div className="header2__list">
				<div className="header2__list__item">
					<img
						src="/images/upsale/pick.png"
						alt="Pick."
						className="header2__list__item__pick"
					/>
					<p className="header2__list__item__text">
						Set up your device with prime live wallpapers
					</p>
				</div>
				<div className="header2__list__item">
					<img
						src="/images/upsale/pick.png"
						alt="Pick."
						className="header2__list__item__pick"
					/>
					<p className="header2__list__item__text">
						Customize your charshing screen with exiciting animations
					</p>
				</div>
			</div>
		</div>
	)
}

export { Header2 }
