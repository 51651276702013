import React from 'react'

import './styles.scss'

const Offer = ({
	unsubscribeTotal,
	onResubscriptionBtnClick,
	onCancelSubscriptionBtnClick,
}) => {
	return (
		<div className="unsubscribe-offer">
			<div className="unsubscribe-offer__title">
				{'Thanks for your feedback.'}
				<br />
				<span className="unsubscribe-offer__pink-text">
					{'Take your gift!'}
				</span>
			</div>
			<img
				className="unsubscribe-offer__img"
				src="/images/unsubscribe/man-with-discount.png"
				alt="man-with-discount"
			/>
			<div className="unsubscribe-offer__price">
				{'Lifetime Access for'}{' '}
				<span className="unsubscribe-offer__pink-text">
					${unsubscribeTotal}
				</span>
			</div>
			<button
				className="unsubscribe-offer__resubscribe-btn"
				onClick={onResubscriptionBtnClick}
			>
				{'Unsubscribe and'} <br />
				{`get Lifetime Access for $${unsubscribeTotal}`}
			</button>
			<button
				className="unsubscribe-offer__unsubscribe-btn"
				onClick={onCancelSubscriptionBtnClick}
			>
				{'Cancel Subscription Anyway'}
			</button>
		</div>
	)
}
export default Offer
