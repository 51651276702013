import React from 'react'

import { Button } from '../../common'

import './styles.scss'

const Footer = ({ onUpgradeBtnClick, onSkipBtnClick, btnDisabled }) => {
	return (
		<div className="upsale-footer">
			<p className="upsale-footer__price">
				<span className="accent">$9.99 USD </span>will be added to your order
			</p>
			<Button
				buttonText="Upgrade My Order"
				onClick={onUpgradeBtnClick}
				disabled={btnDisabled}
			/>
			<button
				className="upsale-footer__skip-button"
				onClick={onSkipBtnClick}
				disabled={btnDisabled}
			>
				No, thank you
			</button>
		</div>
	)
}

export { Footer }
