export const sendSuccessEmail = async (email, user_id) => {
	const body = {
		email,
		user_id,
		type_id: 2,
		project_id: process.env.REACT_APP_PROJECT_ID,
		report_url: `${window.location.origin}/success?user_id=${user_id}&billed=true`,
	}

	await fetch(`${process.env.REACT_APP_SERVER_URL}/api/sendios/email`, {
		method: 'POST',
		headers: {
			auth: '135017',
			'Content-Type': 'application/json',
		},
		body: JSON.stringify(body),
	})
}
