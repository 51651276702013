export const offer = {
	oldPrice: 9.99,
	price: 6.99,
	product_id: process.env.REACT_APP_DISCOUNT_PRODUCT_ID,
	type: 'subscription',
	initial_price: 0.0,
	rebill_price: 6.99,
	trial_period: 3,
	billing_period: 7,
}
