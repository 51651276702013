import React from 'react'
import { Link } from 'react-router-dom'

import { Button } from '../../common'
import { OfferPlan } from '../offerPlan'

import { ampEvent } from '../../../utils/amplitude'
import { flowTypes } from '../../../utils/flowTypes'

import './styles.scss'
import { paramsToQuery } from '../../../utils/paramsToQuery'

const Price = ({
	flow,
	saleFlow,
	user_id,
	setIsShownModal,
	offers,
	handleSelectOffer,
	selectedOffer,
}) => {
	const handleBuySubscription = () => {
		setIsShownModal(true)
		setTimeout(() => {
			ampEvent('Checkout_shown')
		}, 0)
		document.body.style.overflow = 'hidden'
	}

	const renderPrice = () => {
		switch (flow) {
			case flowTypes.LEGAL: {
				return (
					<div className="price__offer-plans">
						{offers.map(offer => (
							<OfferPlan
								key={offer.id}
								onOfferClicked={() => handleSelectOffer(offer.id)}
								{...offer}
							/>
						))}
						<Button buttonText="Try now" onClick={handleBuySubscription} />
					</div>
				)
			}
			case flowTypes.DEFAULT:
			case flowTypes.KEYBOARD: {
				return (
					<>
						<div className="price-info">
							<h2 className="price-info-title">
								Total due today: <span className="accent">$0.00</span>
							</h2>
							<span className="price-info-subtitle">
								Included in your membership:
							</span>
							<div className="price-info-offers">
								<div className="pick-icon-wrapper">
									<img
										src="/images/sale/pick.png"
										alt="Pick."
										className="pick-icon"
									/>
								</div>
								<span className="price-offer">
									{saleFlow === 'original'
										? 'Widget brings you 100% to unique apps features'
										: 'More than 1200 unique features for your phone'}
								</span>
								<div className="pick-icon-wrapper">
									<img
										src="/images/sale/pick.png"
										alt="Pick."
										className="pick-icon"
									/>
								</div>
								<span className="price-offer">
									{saleFlow === 'original'
										? 'Get tracked, monitored, and entertained 24/7'
										: 'Personal customization'}
								</span>
								<div className="pick-icon-wrapper">
									<img
										src="/images/sale/pick.png"
										alt="Pick."
										className="pick-icon"
									/>
								</div>
								<span className="price-offer">
									{saleFlow === 'original'
										? 'Define news faster, get 5 times more influential'
										: 'Trendy & aesthetic'}
								</span>
							</div>
						</div>
						<Button buttonText="Try for free" onClick={handleBuySubscription} />
					</>
				)
			}
			case flowTypes.THEMES: {
				return (
					<>
						{saleFlow === 'test' ? (
							<>
								<p className="price-info-title test">
									Total due today: <span className="accent">$0.00</span>
								</p>
								<div className="price-info-offers">
									<div className="pick-icon-wrapper-themes">
										<img
											src="/images/sale/pick.png"
											alt="Pick."
											className="pick-icon"
										/>
									</div>
									<span className="price-offer">
										More than 1200 unique features for your phone
									</span>
									<div className="pick-icon-wrapper-themes">
										<img
											src="/images/sale/pick.png"
											alt="Pick."
											className="pick-icon"
										/>
									</div>
									<span className="price-offer">
										Get tracked, monitored, and entertained 24/7
									</span>
									<div className="pick-icon-wrapper-themes">
										<img
											src="/images/sale/pick.png"
											alt="Pick."
											className="pick-icon"
										/>
									</div>
									<Link
										className="price-offer-link"
										to={{
											pathname: '/money-back-policy',
											search: paramsToQuery({ user_id }),
										}}
									>
										Money-back Policy
									</Link>
									<div className="pick-icon-wrapper-themes">
										<img
											src="/images/sale/pick.png"
											alt="Pick."
											className="pick-icon"
										/>
									</div>
									<span className="price-offer">
										Define news faster, get more influential
									</span>
								</div>
								{/* <Button
									buttonText="Try for free"
									onClick={handleBuySubscription}
								/> */}
							</>
						) : (
							<>
								<div className="price-info-offers">
									<div className="pick-icon-wrapper-themes">
										<img
											src="/images/sale/pick.png"
											alt="Pick."
											className="pick-icon"
										/>
									</div>
									<span className="price-offer">
										More than 1200 unique features for your phone
									</span>
									<div className="pick-icon-wrapper-themes">
										<img
											src="/images/sale/pick.png"
											alt="Pick."
											className="pick-icon"
										/>
									</div>
									<span className="price-offer">
										Get tracked, monitored, and entertained 24/7
									</span>
									<div className="pick-icon-wrapper-themes">
										<img
											src="/images/sale/pick.png"
											alt="Pick."
											className="pick-icon"
										/>
									</div>
									<span className="price-offer">
										Define news faster, get more influential
									</span>
								</div>
								<Button
									buttonText="Try for free"
									onClick={handleBuySubscription}
								/>
							</>
						)}
					</>
				)
			}
			default: {
				return <p>Component now found</p>
			}
		}
	}

	return (
		<div
			className={`price ${
				flow === flowTypes.THEMES && saleFlow === 'test' ? 'test' : ''
			}`}
		>
			<div
				className={`price-header ${flow === flowTypes.LEGAL ? 'legal' : ''}`}
			>
				{flow === flowTypes.LEGAL || flow === flowTypes.DEFAULT ? (
					<>
						<div
							className={`price-header-rating ${
								flow === flowTypes.LEGAL ? 'legal' : ''
							}`}
						>
							<img
								src="/images/sale/stars-rating.png"
								alt="5 stars rating."
								className="price-header-rating-stars"
							/>
							<span className="price-header-rating-label">30k+ reviews</span>
						</div>
					</>
				) : (
					<></>
				)}
				{flow !== flowTypes.THEMES && (
					<h2
						className={`price-header-title ${
							flow === flowTypes.LEGAL ? 'legal' : ''
						}`}
					>
						Start Your
						<br />
						<span className="price-header-title-gradient">
							{flow === flowTypes.LEGAL
								? '3 days Trial for $0.99'
								: 'Free Trial'}
						</span>
					</h2>
				)}
			</div>
			{flow !== flowTypes.THEMES && (
				<>
					<div className="price-video-container" id="price">
						<video
							autoPlay="autoplay"
							loop
							muted
							src="/videos/sale/price.mp4"
							type="video/mp4"
							className="price-video"
							playsInline
						/>
						<div className="price-image-wrapper">
							<img
								src="/images/sale/price-frame.png"
								alt="Phone frame."
								className="price-image"
							/>
						</div>
					</div>
					<div className="price-border"></div>
				</>
			)}
			{renderPrice()}
			<span
				className={`price-additional ${
					flow === flowTypes.THEMES && saleFlow === 'test' ? 'test' : ''
				}`}
				id="price"
			>
				{flow === flowTypes.LEGAL ? (
					<>
						Unless you cancel{' '}
						<span className="price-additional-bold">at least 24 hours</span>{' '}
						before the end of the{' '}
						<span className="price-additional-bold">3-day trial</span>, you will
						be automatically charged{' '}
						<span className="price-additional-bold">
							U.S.$
							{selectedOffer.pricePerPeriod}
						</span>{' '}
						for a
						<span className="price-additional-bold">
							{' '}
							{selectedOffer.periodTime === 1
								? 'one'
								: selectedOffer.periodTime}
							-{selectedOffer.period}
						</span>{' '}
						subscription. Subscriptions{' '}
						<span className="price-additional-bold">renew automatically</span>{' '}
						at the end of each period unless you cancel at least 24 hours before
						the end of then-current period. <br />
						To avoid being charged, cancel the subscription in your account
						settings. If you are unsure how to cancel, visit our{' '}
						<Link
							className="text-link"
							to={{
								pathname: '/subscription-terms',
								search: paramsToQuery({ user_id }),
							}}
						>
							Subscription Terms
						</Link>
						.
					</>
				) : (
					<>
						No charges for 3 days
						<br />
						Cancel anytime · $9.99/weekly
					</>
				)}
			</span>
		</div>
	)
}

export { Price }
