import React, { useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import Offer from '../components/offer'
import Question from '../components/question'
import Success from '../components/success'
import unsubscribeFetch from '../../../api/unsubscribeFetch'
import { paramsToQuery } from '../../../utils/paramsToQuery'
import { QueryHandler } from '../queryHandler/QueryHandler'
import * as queryString from 'query-string'

import {
	getEmail,
	getUserId,
	setAnnualPlan,
	setDiscountPrice,
	setTotal,
	setUnsubscribeReason,
	showUnsubscribe,
} from '../../../redux/slices/userSlice'
import './styles.scss'
import { Modal } from '../../../components/sale'
import { ampEvent, ampEventWithEventProperty } from '../../../utils/amplitude'

const TIMER_DELAY_TIMEOUT = 350
const UNSUBSCRIBE_TOTAL = 9.99
const VIEWPORT_RESIZE_TO_PX = 0.01
const TOTAL = 999

const UnsubscribeScreen = () => {
	let vh
	const { search, pathname } = useLocation()
	const email = useSelector(getEmail)
	const user_id = useSelector(getUserId)

	const dispatch = useDispatch()
	const history = useHistory()
	const { stage } = useParams()
	const data = queryString.parse(search)

	const [unsubscribeAnswers, setUnsubscribeAnswers] = useState([
		{ id: 1, text: 'Price is too high for me', selected: false },
		{ id: 2, text: 'I have no time for that', selected: false },
	])
	const [textareaValue, setTextareaValue] = useState('')
	const [isShowSendBtn, setIsShowSendBtn] = useState(false)
	const [isShownModal, setIsShownModal] = useState(false)
	const [isActive, setIsActive] = useState(false)

	const onWindowResize = () => {
		vh = window.innerHeight * VIEWPORT_RESIZE_TO_PX
		document.documentElement.style.setProperty('--vh', `${vh}px`)
	}

	useEffect(() => {
		setTimeout(() => {
			if (data.modal) {
				ampEvent('Checkout_shown')
			}
		}, TIMER_DELAY_TIMEOUT)
	}, [])

	useEffect(() => {
		setTimeout(() => {
			if (pathname === '/unsubscribe/reason') {
				ampEvent('start_cancel_subscription')
			}
		}, TIMER_DELAY_TIMEOUT)
	}, [pathname])

	useEffect(() => {
		QueryHandler(email, search, {
			setIsShownModal,
			dispatch,
			history,
		})
	})

	useEffect(() => {
		onWindowResize()
		window.addEventListener('resize', onWindowResize)
	}, [])

	const onSendBtnClick = () => {
		dispatch(setUnsubscribeReason({ unsubscribeReason: textareaValue }))
		setTimeout(() => {
			setIsActive(true)
			setTimeout(() => {
				ampEventWithEventProperty('question_cancel_subscription ', {
					answer: textareaValue,
				})
			}, 0)
			history.push({
				pathname: `/unsubscribe/offer`,
				search: paramsToQuery({
					user_id,
					email: email,
				}),
			})
		}, TIMER_DELAY_TIMEOUT)
	}

	const onAnswerClick = (id, e) => {
		dispatch(setUnsubscribeReason({ unsubscribeReason: e.target.innerText }))
		setUnsubscribeAnswers(setSelectedAnswers(id))
		setIsActive(false)
		setTimeout(() => {
			setIsActive(true)
			setTimeout(() => {
				ampEventWithEventProperty('question_cancel_subscription ', {
					answer: e.target.innerText,
				})
			}, 0)
			history.push({
				pathname: `/unsubscribe/offer`,
				search: paramsToQuery({
					user_id,
					email: email,
				}),
			})
		}, TIMER_DELAY_TIMEOUT)
	}

	const setSelectedAnswers = id =>
		unsubscribeAnswers.map(elem => {
			if (id === elem.id) {
				return {
					...elem,
					selected: true,
				}
			}
			return { ...elem, selected: false }
		})

	const onTextAreaClick = () => {
		setIsShowSendBtn(true)
		setUnsubscribeAnswers(setSelectedAnswers(-1))
	}

	const onCancelSubscriptionBtnClick = () => {
		unsubscribeFetch({ email }).then(res => {
			setIsActive(false)
			setTimeout(() => {
				setIsActive(true)
				setTimeout(() => {
					ampEventWithEventProperty('gift_shown ', {
						value: 'cancel subscription',
					})
					ampEvent('cancel_subscription')
				}, 0)
				history.push({
					pathname: `/unsubscribe/success`,
					search: paramsToQuery({
						user_id,
						email: email,
					}),
				})
			}, TIMER_DELAY_TIMEOUT)
		})
	}

	const onResubscriptionBtnClick = () => {
		unsubscribeFetch({ email }).then(res => {
			setIsShownModal(true)
			ampEventWithEventProperty('gift_shown ', {
				value: 'unsubscribe with lifetime access',
			})
			ampEvent('cancel_subscription')
			setTimeout(() => {
				ampEvent('Checkout_shown')
			}, TIMER_DELAY_TIMEOUT)
		})
		dispatch(setAnnualPlan({ annualPlan: UNSUBSCRIBE_TOTAL }))
		dispatch(setTotal({ total: TOTAL }))
		dispatch(setDiscountPrice({ discountPrice: 0 }))
	}

	const onSkipBtnClick = () => {
		dispatch(setUnsubscribeReason({ unsubscribeReason: '' }))
		setIsActive(false)
		setTimeout(() => {
			setIsActive(true)
			setTimeout(() => {
				ampEventWithEventProperty('question_cancel_subscription ', {
					answer: 'skip',
				})
			}, 0)
			history.push({
				pathname: `/unsubscribe/offer`,
				search: paramsToQuery({
					user_id,
					email: email,
				}),
			})
		}, TIMER_DELAY_TIMEOUT)
	}

	useEffect(() => {
		dispatch(showUnsubscribe({ isUnsubscribeShown: true }))
		setIsActive(false)
		setTimeout(() => {
			setIsActive(true)
		}, TIMER_DELAY_TIMEOUT)

		QueryHandler(search, {
			setIsShownModal,
			dispatch,
			history,
		})
	}, [])

	const unsubscribeStageControlle = () => {
		switch (stage) {
			case 'reason':
				return (
					<Question
						unsubscribeAnswers={unsubscribeAnswers}
						textareaValue={textareaValue}
						setTextareaValue={setTextareaValue}
						onTextAreaClick={onTextAreaClick}
						isShowSendBtn={isShowSendBtn}
						onAnswerClick={onAnswerClick}
						onSendBtnClick={onSendBtnClick}
						onSkipBtnClick={onSkipBtnClick}
					/>
				)
			case 'offer':
				return (
					<>
						<Offer
							unsubscribeTotal={UNSUBSCRIBE_TOTAL}
							onResubscriptionBtnClick={onResubscriptionBtnClick}
							onCancelSubscriptionBtnClick={onCancelSubscriptionBtnClick}
						/>

						<Modal
							total={TOTAL}
							isShownModal={isShownModal}
							setIsShownModal={setIsShownModal}
							annualPlan={UNSUBSCRIBE_TOTAL}
							discount={0}
							productId={process.env.REACT_APP_LIFETIME_UNSUBSCRIBE_PRODUCT_ID}
							lifetime={true}
						/>
					</>
				)
			case 'success':
				return <Success />
			default:
				return <>Component not found</>
		}
	}

	return (
		<div
			className={`unsubscribe-screen ${
				isActive ? 'unsubscribe-screen--active' : 'unsubscribe-screen--unactive'
			}`}
		>
			{unsubscribeStageControlle()}
		</div>
	)
}

export default UnsubscribeScreen
