const getIsAlreadySubscribed = email => {
	const requestOptions = {
		method: 'GET',
		redirect: 'follow',
	}

	return fetch(`${process.env.REACT_APP_GET_USER_URL}/${email}`, requestOptions)
}

export { getIsAlreadySubscribed }
