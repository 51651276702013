import React from 'react'
import { useDispatch } from 'react-redux'

import { answerQuestionMultiple } from '../../../redux/slices/questionsSlice'
import { AnswerNotification } from '../common'
import { Button } from '../../common'

import './styles.scss'

const NotificationAnswers = ({ question, nextQuestion }) => {
	const dispatch = useDispatch()
	const [selectedAnswers, setSelectedAnswers] = React.useState([])

	const onAnswerQuestion = () => {
		dispatch(
			answerQuestionMultiple({ questionId: question.id, selectedAnswers }),
		)
		setSelectedAnswers([])
		nextQuestion()
	}

	const onSelectAnswer = index => {
		const isAlreadySelected = selectedAnswers.some(
			answerIndex => answerIndex === index,
		)
		if (isAlreadySelected) {
			setSelectedAnswers(
				selectedAnswers.filter(answerIndex => answerIndex !== index),
			)
			return
		}
		setSelectedAnswers(
			[...selectedAnswers, index].sort((a, b) => (a > b ? 1 : -1)),
		)
	}

	return (
		<div className="list-notification-answers">
			{question.answers.map((answer, answerIndex) => (
				<AnswerNotification
					key={answer + answerIndex}
					onSelectAnswer={() => onSelectAnswer(answerIndex)}
					answer={answer}
				/>
			))}
			<div className="list-notification-answers__button-wrapper">
				<Button
					buttonText={question.buttonText}
					onClick={onAnswerQuestion}
					disabled={!selectedAnswers.length}
				/>
			</div>
		</div>
	)
}

export { NotificationAnswers }
