import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { answerQuestionSingle } from '../../../redux/slices/questionsSlice'

import './styles.scss'

import { getSelectedTheme } from '../../../redux/slices/userSlice'

const RectangleTheme = ({ question, nextQuestion }) => {
	const selectedTheme = useSelector(getSelectedTheme)
	const dispatch = useDispatch()

	const onAnswer = answer => {
		setTimeout(() => {
			dispatch(answerQuestionSingle({ questionId: question.id, answer }))
			nextQuestion()
		}, 400)
	}

	return (
		<>
			<div className="theme-container">
				<img
					src={`/images/questions/rectangleThemes/${selectedTheme}-${question.id}.png`}
					alt="theme"
					className="theme-container__image"
				/>
				<div className="theme-container__answers">
					{question.answers.map((answer, answerIndex) => (
						<button
							key={answer + answerIndex}
							className={`theme-container__answers-button ${answer}`}
							onClick={() => onAnswer(answer)}
						>
							{answer}
						</button>
					))}
				</div>
			</div>
			<img
				src={`/images/questions/rectangleThemes/${selectedTheme}-${
					+question.id + 1
				}.png`}
				alt="theme"
				className="none-media"
			/>
		</>
	)
}

export { RectangleTheme }
