import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import * as queryString from 'query-string'

import { Header, Main, Offer } from '../../components/discount'
import { Modal } from '../../components/sale'
import { Footer } from '../../components/common'

import { ampEvent } from '../../utils/amplitude'
import { QueryHandler } from '../sale/handlers/queryHandler/queryHandler'
import { paramsToQuery } from '../../utils/paramsToQuery'
import { offer } from './offer'

import './styles.scss'
import {
	getUserId,
	setAnnualPlan,
	setDiscountPrice,
	setProductId,
	setTotal,
	getIsOnlyLegalFlow,
	setCurrentOffer,
} from '../../redux/slices/userSlice'

const DiscountScreen = () => {
	const dispatch = useDispatch()
	const history = useHistory()
	const { search, pathname } = useLocation()
	const data = queryString.parse(search)

	const [isShownModal, setIsShownModal] = React.useState(false)

	const user_id = useSelector(getUserId)
	const isOnlyLegalFlow = useSelector(getIsOnlyLegalFlow)

	React.useEffect(() => {
		document.getElementById('discount')?.scrollIntoView()
		dispatch(setTotal({ total: 699 }))
		dispatch(setAnnualPlan({ annualPlan: 6.99 }))
		dispatch(setDiscountPrice({ discountPrice: 6.99 }))
		dispatch(
			setProductId({ productId: process.env.REACT_APP_DISCOUNT_PRODUCT_ID }),
		)
		dispatch(setCurrentOffer({ currentOffer: offer }))

		setTimeout(() => {
			ampEvent('Discount_screen_shown')
			if (data.modal) {
				ampEvent('Checkout_shown')
			}
		}, 100)

		QueryHandler(data.email, search, {
			setIsShownModal,
			dispatch,
			history,
		})
	}, [])

	React.useEffect(() => {
		if (isOnlyLegalFlow && pathname.includes('/discount')) {
			history.push({
				pathname: `/sale`,
				search: paramsToQuery(data),
			})
		}
	}, [isOnlyLegalFlow])

	return (
		<div className="discount" id="discount">
			<Header />
			<Main setIsShownModal={setIsShownModal} />
			<Offer setIsShownModal={setIsShownModal} />
			<Footer user_id={user_id} />
			<Modal
				setIsShownModal={setIsShownModal}
				isShownModal={isShownModal}
				total={699}
				annualPlan={6.99}
				discount={6.99}
				productId={process.env.REACT_APP_DISCOUNT_PRODUCT_ID}
			/>
		</div>
	)
}

export { DiscountScreen }
