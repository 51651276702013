import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import { PersistGate } from 'redux-persist/integration/react'
import SwiperCore, { Autoplay, Pagination } from 'swiper/core'

import App from './App'
import { persistor, store } from './redux/store'
import HeaderProvider from './components/common/hoc/HeaderProvider'

import 'swiper/swiper.min.css'
import 'swiper/components/pagination/pagination.min.css'
import './styles/styles.scss'

SwiperCore.use([Autoplay, Pagination])

ReactDOM.render(
	<React.StrictMode>
		<Provider store={store}>
			<PersistGate loading={null} persistor={persistor}>
				<BrowserRouter>
					<HeaderProvider>
						<App />
					</HeaderProvider>
				</BrowserRouter>
			</PersistGate>
		</Provider>
	</React.StrictMode>,
	document.getElementById('root'),
)
