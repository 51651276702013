import React, { useEffect, useRef } from 'react'

import lottie from 'lottie-web'

import './styles.scss'

const ThemeMain = ({
	flow,
	saleFlow,
	title,
	text,
	mask,
	buttonText,
	onClick,
}) => {
	const animationRef = useRef()

	let margin
	let padding
	if (saleFlow === 'original') {
		margin = 90
		padding = 69
	} else {
		margin = 10
		padding = 69
	}

	useEffect(() => {
		lottie.loadAnimation({
			container: animationRef.current,
			renderer: 'svg',
			loop: true,
			autoplay: true,
			animationData: require('./animation.json'),
		})
	}, [])
	return (
		<div
			className="theme-main"
			style={{ marginBottom: margin, paddingTop: padding }}
		>
			<h2 className="theme-main__title">{title}</h2>
			<span className="theme-main__label">{text}</span>
			<div className="theme-main__animation" ref={animationRef} />
			<div className="theme-main__wrapper">
				<img
					className="theme-main__mask"
					src={`/images/sale/${mask}.png`}
					alt="Mask."
				/>
			</div>
		</div>
	)
}

export { ThemeMain }
