import React from 'react'

import './styles.scss'

const AnswerCircle = ({ widget, font, circleStyle, onSelectAnswer }) => {
	const [isSelected, setIsSelected] = React.useState(false)

	return (
		<div
			className={`circle ${isSelected ? 'selected' : ''}`}
			style={{ ...circleStyle }}
			onClick={() => {
				setIsSelected(!isSelected)
				onSelectAnswer()
			}}
		>
			{widget ? (
				<img
					src={`/images/questions/widgetAnswers/${widget}.png`}
					alt="Widget."
					className="circle-image"
					style={{
						width: circleStyle.imageWidth,
						height: circleStyle.imageHeight,
					}}
				/>
			) : (
				<img
					src={`/images/questions/fontAnswers/${font}.png`}
					alt="Font."
					className="circle-image"
					style={{
						width: circleStyle.imageWidth,
						height: circleStyle.imageHeight,
					}}
				/>
			)}
		</div>
	)
}

export { AnswerCircle }
