import React from 'react'
import { Link } from 'react-router-dom'
import { paramsToQuery } from '../../../utils/paramsToQuery'

import { flowTypes } from '../../../utils/flowTypes'

import './styles.scss'
import { Price, Timer } from '../../sale'

const Footer = ({
	user_id,
	flow,
	saleScreenABTestValue,
	setIsShownModal,
	selectedOffer,
	offers,
	handleSelectOffer,
}) => {
	return (
		<>
			{flow !== flowTypes.THEMES ? (
				<div className="footer">
					<div className="footer-payment">
						<div className="footer-payment-secure">
							<img
								src="/images/sale/shield-icon.png"
								alt="Shield."
								className="footer-payment-secure-icon"
							/>
							<span className="footer-payment-secure-title">
								Pay safe & secure
							</span>
						</div>
						<img
							src="/images/sale/payment-methods.png"
							alt="Payment methods."
							className="footer-payment-image"
						/>
					</div>
					<div className="footer-general">
						<div className="footer-general-links">
							<a
								href="https://mail.google.com/mail/?view=cm&fs=1&to=support@widget-box.me"
								className="link"
								target="_blank"
								rel="noreferrer"
							>
								Contact Us
							</a>
							<Link
								className="link"
								to={{
									pathname: '/privacy-policy',
									search: paramsToQuery({ user_id }),
								}}
							>
								Privacy Policy
							</Link>
							<Link
								className="link"
								to={{
									pathname: '/terms-of-use',
									search: paramsToQuery({ user_id }),
								}}
							>
								Terms of Use
							</Link>
						</div>
						<div className="footer-general__money-back">
							<div className="footer-general__money-back__wrapper">
								<div className="footer-general__money-back__wrapper__content">
									<h3 className="footer-general__money-back__wrapper__content__title">
										Money-back
										<br />
										guarantee
									</h3>
									<p className="footer-general__money-back__wrapper__content__text">
										<span className="footer-general__money-back__wrapper__content__text__accent">
											14-day money back guarantee
										</span>
										, which entitles you to a full refund upon cancellation and
										emailing{' '}
										<a
											href="https://mail.google.com/mail/?view=cm&fs=1&to=support@widget-box.me"
											className="link"
											target="_blank"
											rel="noreferrer"
										>
											support@widget-box.me
										</a>{' '}
										to request a refund. Such refund requests must be made
										within the first 14 calendar days from your first date of
										payment. Find more about applicable limitations in our{' '}
										<br />{' '}
										<Link
											className="link"
											to={{
												pathname: '/money-back-policy',
												search: paramsToQuery({ user_id }),
											}}
										>
											Money-back Policy
										</Link>
										.
									</p>
								</div>
							</div>
							<img
								src="/images/sale/rosette.png"
								alt="Rosette."
								className="footer-general__money-back__rosette"
							/>
						</div>
						<span className="footer-general-copyright">
							GM Unicorn Corporation Limited Florinis 7, Greg Tower,2nd Floor,
							1065, Nicosia, Cyprus
						</span>
					</div>
				</div>
			) : (
				<div className="footer">
					<div
						className="footer-general__money-back"
						style={{
							backgroundColor: 'black',
							boxShadow: '0px 0px 20px 10px rgba(46, 54, 71, 0.7)',
						}}
					>
						<div
							className="footer-general__money-back__wrapper"
							style={{ background: 'white' }}
						>
							<div
								className="footer-general__money-back__wrapper__content"
								style={{ background: 'black', padding: '46px 19px 54px' }}
							>
								<h3 className="footer-general__money-back__wrapper__content__title">
									Money-back
									<br />
									guarantee
								</h3>
								<p
									className="footer-general__money-back__wrapper__content__text"
									style={{ margin: '30px 0 0' }}
								>
									<span className="footer-general__money-back__wrapper__content__text__accent">
										14-day money back guarantee
									</span>
									, which entitles you to a full refund upon cancellation and
									emailing{' '}
									<a
										href="https://mail.google.com/mail/?view=cm&fs=1&to=support@widget-box.me"
										className="link"
										target="_blank"
										rel="noreferrer"
									>
										support@widget-box.me
									</a>{' '}
									to request a refund. Such refund requests must be made within
									the first 14 calendar days from your first date of payment.
									Find more about applicable limitations in our <br />{' '}
									<Link
										className="link"
										to={{
											pathname: '/money-back-policy',
											search: paramsToQuery({ user_id }),
										}}
									>
										Money-back Policy
									</Link>
									.
								</p>
							</div>
						</div>
						<img
							src="/images/sale/rosette.png"
							alt="Rosette."
							className="footer-general__money-back__rosette"
						/>
					</div>
					<h1 className="footer-title" id="second-price">
						Customize your phone <br /> with WidgetBox
					</h1>
					<Timer />
					<h1 className="footer-title" style={{ marginBottom: 5 }}>
						Total due today:&nbsp;<span className="accent"> $0.00</span>
					</h1>
					<Price
						flow={flow}
						saleFlow={saleScreenABTestValue}
						user_id={user_id}
						setIsShownModal={setIsShownModal}
						selectedOffer={selectedOffer}
						offers={offers}
						handleSelectOffer={handleSelectOffer}
					/>
					<div className="footer-payment">
						<div className="footer-payment-secure">
							<img
								src="/images/sale/shield-icon.png"
								alt="Shield."
								className="footer-payment-secure-icon"
							/>
							<span className="footer-payment-secure-title">
								Pay safe & secure
							</span>
						</div>
						<img
							src="/images/sale/payment-methods.png"
							alt="Payment methods."
							className="footer-payment-image"
						/>
					</div>
					<div className="footer-general">
						<div className="footer-general-links">
							<a
								href="https://mail.google.com/mail/?view=cm&fs=1&to=support@widget-box.me"
								className="link"
								target="_blank"
								rel="noreferrer"
							>
								Contact Us
							</a>
							<Link
								className="link"
								to={{
									pathname: '/privacy-policy',
									search: paramsToQuery({ user_id }),
								}}
							>
								Privacy Policy
							</Link>
							<Link
								className="link"
								to={{
									pathname: '/terms-of-use',
									search: paramsToQuery({ user_id }),
								}}
							>
								Terms of Use
							</Link>
						</div>
						<span className="footer-general-copyright">
							GM Unicorn Corporation Limited Florinis 7, Greg Tower,2nd Floor,
							1065, Nicosia, Cyprus
						</span>
					</div>
				</div>
			)}
		</>
	)
}

export { Footer }
