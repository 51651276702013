export const types = {
	SQUARE_ANSWERS: 'SQUARE_ANSWERS',
	TEXT_CIRCLES_ANSWERS: 'TEXT_CIRCLES_ANSWERS',
	WIDGET_CIRCLES_ANSWERS: 'WIDGET_CIRCLES_ANSWERS',
	NOTIFICATION_ANSWERS: 'NOTIFICATION_ANSWERS',
	WIDGET_SQUARE_ANSWERS: 'WIDGET_SQUARE_ANSWERS',
	KEYBOARD_ANSWERS: 'KEYBOARD_ANSWERS',
	SQUARE_THEMES: 'SQUARE_THEMES',
	RECTANGLE_THEMES: 'RECTANGLE_THEMES',
}
