import React from 'react'
import { useDispatch } from 'react-redux'

import { symbolsWidth } from './common'

import { Button } from '../../common'
import { SquareAnswer } from '../common'
import { AnswerWidget } from '../common/answerWidget'
import { types } from '../../../redux/initialStates/questions/questionTypes'
import { answerQuestionMultiple } from '../../../redux/slices/questionsSlice'

import './styles.scss'

const SquareAnswers = ({ question, nextQuestion }) => {
	const dispatch = useDispatch()
	const [selectedAnswers, setSelectedAnswers] = React.useState([])

	const onAnswerQuestion = () => {
		dispatch(
			answerQuestionMultiple({ questionId: question.id, selectedAnswers }),
		)
		setSelectedAnswers([])
		nextQuestion()
	}

	const onSelectAnswer = index => {
		const isAlreadySelected = selectedAnswers.some(
			answerIndex => answerIndex === index,
		)
		if (isAlreadySelected) {
			setSelectedAnswers(
				selectedAnswers.filter(answerIndex => answerIndex !== index),
			)
			return
		}
		setSelectedAnswers(
			[...selectedAnswers, index].sort((a, b) => (a > b ? 1 : -1)),
		)
	}

	const renderAnswers = (answer, answerIndex) => {
		switch (question.componentType) {
			case types.SQUARE_ANSWERS: {
				return (
					<SquareAnswer
						key={answer + answerIndex}
						onSelectAnswer={() => onSelectAnswer(answerIndex)}
						answer={answer}
					/>
				)
			}
			case types.WIDGET_SQUARE_ANSWERS: {
				return (
					<AnswerWidget
						key={answer + answerIndex}
						onSelectAnswer={() => onSelectAnswer(answerIndex)}
						symbol={true}
						answer={answer}
						questionId={question.id}
						imageWidth={symbolsWidth[answerIndex].width}
					/>
				)
			}
			default: {
				return <p>Component not found</p>
			}
		}
	}

	return (
		<>
			<div className="list-square-answers">
				{question.answers.map((answer, answerIndex) =>
					renderAnswers(answer, answerIndex),
				)}
			</div>
			<div className="button-wrapper">
				<Button
					buttonText={question.buttonText}
					onClick={onAnswerQuestion}
					disabled={!selectedAnswers.length}
				/>
			</div>
		</>
	)
}

export { SquareAnswers }
