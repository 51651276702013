export const symbolsWidth = [
	// 1
	{
		width: 110,
	},
	// 2
	{
		width: 99,
	},
	// 3
	{
		width: 99,
	},
	// 4
	{
		width: 67,
	},
	// 5
	{
		width: 106,
	},
	// 6
	{
		width: 82,
	},
]
