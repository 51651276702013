import React from 'react'
import { flowTypes } from '../../../utils/flowTypes'

import { Review } from '../review/component'

import './styles.scss'

const ReviewsList = ({ flow, saleFlow, title }) => {
	return (
		<div className="review-list">
			{flow === flowTypes.KEYBOARD ? (
				<>
					<div className="review-list__rating">
						<img
							src="/images/sale/stars-review.png"
							alt="4.5 stars rating."
							className="review-list__rating__stars"
						/>
						<span className="review-list__rating__label">40k+ reviews</span>
					</div>
				</>
			) : (
				<></>
			)}
			<h2 className="review-list__title">{title}</h2>
			<Review
				image="stars-rating"
				title="Aesthetic themes and widgets!"
				content="Love this app. I’m not a fan of plain bland home screens. I am so much into aesthetics and this app lets me have aesthetic Home Screen with its new artwork."
			/>
			<Review
				image={`stars-rating${flow === flowTypes.KEYBOARD ? '2' : ''}`}
				title="AWESOME APP! LISTEN TO THIS!!!!!"
				content="This app is awesome and calms me when I look at it. But if you don’t like the cute aesthetic vibes. Just add a plain widget as a helpful thing! I REALLY recommend this app!"
			/>
			<Review
				image="stars-rating"
				title="Great app!!"
				content="I love this app, it's very simple and straightforward."
			/>
		</div>
	)
}

export { ReviewsList }
