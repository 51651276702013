const offersList = [
	{
		id: 0,
		isBestseller: false,
		isSelected: false,
		emoji: 'star-struck',
		period: 'week',
		periodTime: 1,
		price: 5.99,
		pricePerPeriod: '5,99',
		pricePerDayDollars: 1,
		pricePerDayCoins: 43,
		product_id: process.env.REACT_APP_1WEEK_PRODUCT_ID,
		type: 'subscription',
		initial_price: 0.99,
		rebill_price: 5.99,
		trial_period: 3,
		billing_period: 7,
	},
	{
		id: 1,
		isBestseller: true,
		isSelected: true,
		emoji: 'lovely-eyes',
		period: 'month',
		periodTime: 1,
		price: 19.99,
		pricePerPeriod: '19,99',
		pricePerDayDollars: 0,
		pricePerDayCoins: 66,
		product_id: process.env.REACT_APP_1MONTH_PRODUCT_ID,
		type: 'subscription',
		initial_price: 0.99,
		rebill_price: 19.99,
		trial_period: 3,
		billing_period: 30,
	},
	{
		id: 2,
		isBestseller: false,
		isSelected: false,
		emoji: 'cool',
		period: 'month',
		periodTime: 3,
		price: 29.99,
		pricePerPeriod: '29,99',
		pricePerDayDollars: 0,
		pricePerDayCoins: 33,
		product_id: process.env.REACT_APP_3MONTH_PRODUCT_ID,
		type: 'subscription',
		initial_price: 0.99,
		rebill_price: 29.99,
		trial_period: 3,
		billing_period: 90,
	},
]

export { offersList }
