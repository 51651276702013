import React from 'react'

import { Button } from '../../common'

import './styles.scss'

const ThemeCard = ({
	title,
	text,
	image,
	button,
	onClick,
	buttonText,
	saleFlow,
}) => {
	let width
	if (!button && saleFlow === 'original') {
		width = 204
	} else {
		width = 288
	}

	let imageWidth
	if (image.includes('using')) {
		imageWidth = 287
	} else {
		imageWidth = 375
	}

	let marginTop
	let marginBottom
	let padding

	if (saleFlow === 'original') {
		marginBottom = 80
		marginTop = 0
		padding = 0
	} else {
		marginTop = 20
		marginBottom = 10
		padding = 69
	}

	return (
		<div
			className="theme-card"
			style={{
				marginTop: marginTop,
				marginBottom: marginBottom,
				paddingTop: padding,
			}}
		>
			<h1 className="theme-card__title">{title}</h1>
			<p className="theme-card__text" style={{ width: width }}>
				{text}
			</p>
			<img
				src={`/images/sale/${image}.png`}
				alt={image}
				className="theme-card__image"
				style={{ width: imageWidth }}
			/>
			{button && <Button buttonText={buttonText} onClick={onClick} />}
		</div>
	)
}

export { ThemeCard }
