import React from 'react'
import { useSelector } from 'react-redux'

import { getQuestionsCount } from '../../../../redux/slices/questionsSlice'

import './styles.scss'

const QuestionInfo = ({ id, question, width }) => {
	const questionsCount = useSelector(getQuestionsCount)

	return (
		<div className="question-info">
			<span className="question-info__progress">
				QUESTION {id}/{questionsCount}
			</span>
			<h2 className="question-info__question" style={{ width: width }}>
				{question}
			</h2>
		</div>
	)
}

export { QuestionInfo }
