import { createSlice } from '@reduxjs/toolkit'
import { defaultConfig } from '../../../utils/remoteConfigs'

export const STATE_KEY = 'config'

const initialState = {
	saleScreenABTestValue: defaultConfig.saleScreenABTestValue,
}

export const configsSlice = createSlice({
	name: 'config',
	initialState,
	reducers: {
		setSaleScreenABTestValue: (state, action) => {
			state.saleScreenABTestValue = action.payload
		},
	},
})

export const { setSaleScreenABTestValue } = configsSlice.actions

export const getSaleScreenABTestValue = state =>
	state.config.saleScreenABTestValue

export const configReducer = configsSlice.reducer
