import React from 'react'
import { useDispatch } from 'react-redux'

import { SquareTheme } from '../common/squareTheme/component'
import { answerQuestionSingle } from '../../../redux/slices/questionsSlice'
import { setSelectedTheme } from '../../../redux/slices/userSlice'

import './styles.scss'

export const SquareThemes = ({ question, nextQuestion }) => {
	const dispatch = useDispatch()

	const onSelectAnswer = answer => {
		setTimeout(() => {
			dispatch(setSelectedTheme({ selectedTheme: answer?.toLowerCase() }))
			dispatch(answerQuestionSingle({ questionId: question.id, answer }))
			nextQuestion()
		}, 400)
	}

	return (
		<>
			<div className="square-themes">
				<div className="square-themes__list">
					{question.answers.map((answer, answerIndex) => (
						<SquareTheme
							key={answer + answerIndex}
							onSelectAnswer={() => onSelectAnswer(answer)}
							answer={answer}
						/>
					))}
				</div>
			</div>
			<img
				src={`/images/questions/rectangleThemes/cartoons-1.png`}
				alt="theme"
				className="none-media"
			/>
			<img
				src={`/images/questions/rectangleThemes/games-1.png`}
				alt="theme"
				className="none-media"
			/>
			<img
				src={`/images/questions/rectangleThemes/pastel-1.png`}
				alt="theme"
				className="none-media"
			/>
			<img
				src={`/images/questions/rectangleThemes/neon-1.png`}
				alt="theme"
				className="none-media"
			/>
		</>
	)
}
