export const userPropertyFetch = (body, user_id) => {
	fetch(process.env.REACT_APP_ANALYTICS_SERVER_URL + 'userProps/' + user_id, {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
			authorization: process.env.REACT_APP_ANALYTICS_AUTH_HEADER,
		},
		body: JSON.stringify(body),
	}).catch(error => {
		console.log(error)
	})
}

export const purchasePropertyFetch = (body, user_id) => {
	const reqBody = {
		...body,
		datetime: Date.now(),
		app_name: process.env.REACT_APP_PROJECT_NAME?.toUpperCase(),
	}
	fetch(process.env.REACT_APP_ANALYTICS_SERVER_URL + `purchase/${user_id}`, {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
			authorization: process.env.REACT_APP_ANALYTICS_AUTH_HEADER,
		},
		body: JSON.stringify(reqBody),
	}).catch(error => {
		console.log(error)
	})
}

export const productPropertyFetch = user_id => {
	fetch(
		process.env.REACT_APP_ANALYTICS_SERVER_URL + 'userProps/high/' + user_id,
		{
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				authorization: process.env.REACT_APP_ANALYTICS_AUTH_HEADER,
			},
			body: JSON.stringify({ product: 'WIDGETBOX' }),
		},
	).catch(error => {
		console.log(error)
	})
}
