import React from 'react'
import { useDispatch } from 'react-redux'

import { Button } from '../../common'
import { AnswerImage } from '../common'

import { answerQuestionSingle } from '../../../redux/slices/questionsSlice'

import './styles.scss'

const KeyboardAnswers = ({ question, nextQuestion }) => {
	const dispatch = useDispatch()
	const [result, setResult] = React.useState(0)
	const [touched, setTouched] = React.useState(false)

	const onSelectAnswer = (questionId, answerIdx) => {
		setResult(answerIdx)
		dispatch(answerQuestionSingle({ questionId, answerIdx }))
		setTouched(true)
	}

	const onAnswerQuestion = () => {
		nextQuestion()
	}

	return (
		<div className="keyboard-answers">
			<div className="keyboard-answers__example">
				<div className="keyboard-answers__example__back"></div>
				<div className="keyboard-answers__example__wrapper">
					<img
						src={`/images/questions/widgetSquareAnswers/${question.id}/${
							result + 1
						}.png`}
						alt="Example of use custom keyboard theme."
						className="keyboard-answers__example__wrapper__theme"
					/>
					<div className="keyboard-answers__example__wrapper__keyboard">
						<img
							src={`/images/questions/widgetSquareAnswers/${question.id}/keyboard.png`}
							alt="Keyboard."
							className="keyboard-answers__example__wrapper__keyboard__buttons"
						/>
					</div>
				</div>
			</div>
			<div className="keyboard-answers__widgets-list">
				{question.answers.map((answer, answerIndex) => (
					<AnswerImage
						key={answer + answerIndex}
						questionId={question.id}
						answer={answer}
						isActive={result === answerIndex}
						onSelectAnswer={() => onSelectAnswer(question.id, answerIndex)}
					/>
				))}
			</div>
			<div className="keyboard-answers__button-wrapper">
				<Button
					buttonText={question.buttonText}
					disabled={!touched}
					onClick={onAnswerQuestion}
				/>
			</div>
		</div>
	)
}

export { KeyboardAnswers }
