import React, { useEffect, useRef } from 'react'

import * as PDFJSViewer from 'pdfjs-dist/legacy/web/pdf_viewer.js'
import pdfjsWorker from 'pdfjs-dist/legacy/build/pdf.worker.entry'
import * as pdfjsLib from 'pdfjs-dist/legacy/build/pdf'
import 'pdfjs-dist/web/pdf_viewer.css'

import './styles.scss'

pdfjsLib.GlobalWorkerOptions.workerSrc = pdfjsWorker

const PDFViewer = ({ url }) => {
	const pdfViewerRef = useRef()

	useEffect(() => {
		const initPdf = async () => {
			const pdfDocument = await pdfjsLib.getDocument(url).promise

			const eventBus = new PDFJSViewer.EventBus()
			const viewer = new PDFJSViewer.PDFViewer({
				container: pdfViewerRef.current,
				eventBus,
			})
			viewer.setDocument(pdfDocument)

			const resizeHandler = fitValue => {
				viewer.currentScaleValue = fitValue
				viewer.update()
			}

			window.onresize = () => resizeHandler('page-fit')

			setTimeout(() => resizeHandler('page-fit'), 0)
		}

		initPdf()
	}, [])

	return (
		<div ref={pdfViewerRef} className="pdfWrapper" id="pdf-container">
			<div className="pdfViewer" />
		</div>
	)
}

export default PDFViewer
