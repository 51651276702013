export const notLegalOffer = {
	price: 9.99,
	pricePerPeriod: '9,99',
	pricePerDayDollars: 1,
	pricePerDayCoins: 43,
	product_id: process.env.REACT_APP_PRODUCT_ID,
	type: 'subscription',
	initial_price: 0.0,
	rebill_price: 9.99,
	trial_period: 3,
	billing_period: 7,
}
