import React from 'react'

import './styles.scss'

const UpsaleMain = ({ image }) => {
	return (
		<div className="upsale-main">
			<img
				src={`/images/upsale/${image}.png`}
				alt="Example of pack."
				className="upsale-main__image"
			/>
		</div>
	)
}

export { UpsaleMain }
