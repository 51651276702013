export const payPalRequest = async body => {
	if (body.product_id) {
		let data = await fetch(
			`${process.env.REACT_APP_SERVER_URL}/api/paypal/widgetbox/subscription`,
			{
				method: 'POST',
				headers: {
					'Content-type': 'application/json',
					auth: process.env.REACT_APP_PAYPAL_PUBLIC_KEY,
				},
				body: JSON.stringify(body),
			},
		)
		data = await data.json()
		return data
	} else {
		let data = await fetch(
			`${process.env.REACT_APP_SERVER_URL}/api/paypal/widgetbox`,
			{
				method: 'POST',
				headers: {
					'Content-type': 'application/json',
					auth: process.env.REACT_APP_PAYPAL_PUBLIC_KEY,
				},
				body: JSON.stringify(body),
			},
		)
		data = await data.json()
		return data
	}
}

export const cardRequest = async body => {
	if (body.product_id) {
		let data = await fetch(
			`${process.env.REACT_APP_SERVER_URL}/api/card/v2.0/widgetbox/subscription`,
			{
				method: 'POST',
				headers: {
					'Content-type': 'application/json',
					auth: process.env.REACT_APP_CARD_PUBLIC_KEY,
				},
				body: JSON.stringify(body),
			},
		)
		data = await data.json()
		return data
	} else {
		let data = await fetch(
			`${process.env.REACT_APP_SERVER_URL}/api/card/v2.0/widgetbox`,
			{
				method: 'POST',
				headers: {
					'Content-type': 'application/json',
					auth: process.env.REACT_APP_CARD_PUBLIC_KEY,
				},
				body: JSON.stringify(body),
			},
		)
		data = await data.json()
		return data
	}
}
