import React from 'react'

import './styles.scss'

const Input = ({ formValue, setFormValue, handleKeyboardSubmitEmail }) => {
	return (
		<div className="input-wrapper">
			<input
				type="email"
				name="email"
				placeholder="somepost@gmail.com"
				id="email"
				className="input"
				value={formValue}
				onChange={e => setFormValue(e.target.value)}
				onKeyDown={e => handleKeyboardSubmitEmail(e)}
			/>
		</div>
	)
}

export { Input }
