import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { Button, Input } from '../../common'

import validateEmail from '../../../utils/validateEmail'
import {
	getFlow,
	getSelectedTheme,
	setEmail,
} from '../../../redux/slices/userSlice'
import { ampSetUserProperty } from '../../../utils/amplitude'
import { flowTypes } from '../../../utils/flowTypes'
import { getSaleScreenABTestValue } from '../../../redux/slices/config'

import './styles.scss'
import { paramsToQuery } from '../../../utils/paramsToQuery'

const ENTER_KEYCODE = 13

const Form = ({ isAgree, user_id }) => {
	const dispatch = useDispatch()
	const history = useHistory()

	const [formValue, setFormValue] = React.useState('')

	const flow = useSelector(getFlow)
	const saleScreenABTestValue = useSelector(getSaleScreenABTestValue)
	const selectedTheme = useSelector(getSelectedTheme)
	const isValidEmail = isAgree && validateEmail(formValue)

	const handleKeyboardSubmitEmail = e => {
		const { keyCode } = e
		if (keyCode === ENTER_KEYCODE && isValidEmail) {
			onSubmitEmail()
		}
	}

	const onSubmitEmail = () => {
		ampSetUserProperty('email', formValue)
		dispatch(setEmail({ email: formValue }))
		fetch(`${process.env.REACT_APP_GET_USER_URL}/${formValue}`)
			.then(res => res.json())
			.then(({ premium }) => {
				if (premium) {
					setTimeout(
						() =>
							history.push({
								pathname: '/success',
								search: paramsToQuery({ user_id, billed: true }),
							}),
						350,
					)
				} else {
					if (flow === flowTypes.LEGAL) {
						history.push({
							pathname: `/sale${flow}`,
							search: paramsToQuery({
								user_id,
								email: formValue,
								salescreen_test: saleScreenABTestValue,
								message: false,
							}),
						})
					} else if (flow === flowTypes.THEMES) {
						history.push({
							pathname: `/sale${flow}`,
							search: paramsToQuery({
								user_id,
								email: formValue,
								salescreen_test: saleScreenABTestValue,
								message: false,
								theme: selectedTheme,
								flow,
							}),
						})
					} else {
						history.push({
							pathname: `/sale${flow}`,
							search: paramsToQuery({
								user_id,
								email: formValue,
								salescreen_test: saleScreenABTestValue,
								message: false,
								flow,
							}),
						})
					}
				}
			})
	}

	return (
		<div className="email-form">
			<div className="email-form">
				<h2 className="email-form__title">Email</h2>
				{flow === flowTypes.LEGAL ? (
					<span className="email-form__label">
						Widget pack&apos;s on your way
					</span>
				) : (
					<span className="email-form__label">
						Enter your email to get a{' '}
						{flow === flowTypes.THEMES ? ' themes ' : ' widgets '} pack
					</span>
				)}
			</div>
			<Input
				formValue={formValue}
				setFormValue={setFormValue}
				handleKeyboardSubmitEmail={handleKeyboardSubmitEmail}
			/>
			<Button
				buttonText="Continue"
				onClick={onSubmitEmail}
				disabled={!isValidEmail}
			/>
		</div>
	)
}

export { Form }
