import amplitude from 'amplitude-js'
import { userPropertyFetch } from './analyticsRequests'

const ampInst = amplitude.getInstance()

ampInst.init(process.env.REACT_APP_AMPLITUDE_API_KEY)

function ampEvent(event) {
	const body = {
		event_properties: {},
		parameters: {
			user_id: window.user_id,
			event_name: `${event}_back`,
			device_id: ampInst.options.deviceId,
			time: new Date(Date.now()).getTime(),
		},
	}

	ampInst.logEvent(event, {}, () => {
		fetch(
			`${process.env.REACT_APP_ANALYTICS_SERVER_URL}amplitude/event/${ampInst.options.apiKey}`,
			{
				method: 'POST',
				body: JSON.stringify(body),
				headers: {
					'Content-Type': 'application/json',
					Authorization: process.env.REACT_APP_ANALYTICS_AUTH_HEADER,
				},
			},
		).catch(e => console.error(e))
	})
}

function ampEventWithEventProperty(event, props) {
	const body = {
		event_properties: props,
		parameters: {
			user_id: window.user_id,
			event_name: `${event}_back`,
			device_id: ampInst.options.deviceId,
			time: new Date(Date.now()).getTime(),
		},
	}

	ampInst.logEvent(event, props, () => {
		fetch(
			`${process.env.REACT_APP_ANALYTICS_SERVER_URL}amplitude/event/${ampInst.options.apiKey}`,
			{
				method: 'POST',
				body: JSON.stringify(body),
				headers: {
					'Content-Type': 'application/json',
					Authorization: process.env.REACT_APP_ANALYTICS_AUTH_HEADER,
				},
			},
		).catch(e => console.error(e))
	})
}

function ampSetUserProperty(key, value) {
	if (key === 'user_id') {
		ampInst.setUserId(window.user_id)
	} else {
		const identify = new amplitude.Identify().set(key, value)
		ampInst.identify(identify)
		userPropertyFetch({ [key]: value }, window.user_id)
		const body = {
			user_properties: {
				[key]: value,
			},
			parameters: {
				user_id: window.user_id,
				device_id: ampInst.options.deviceId,
			},
		}
		fetch(
			`${process.env.REACT_APP_ANALYTICS_SERVER_URL}amplitude/user/${ampInst.options.apiKey}`,
			{
				method: 'POST',
				body: JSON.stringify(body),
				headers: {
					'Content-Type': 'application/json',
					Authorization: process.env.REACT_APP_ANALYTICS_AUTH_HEADER,
				},
			},
		).catch(e => console.error(e))
	}
}

export { ampEvent, ampEventWithEventProperty, ampSetUserProperty }
