import React from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { Button } from '../../components/common'

import {
	getDiscount,
	getEmail,
	getError,
	getFlow,
	getIsSendConfirmEmail,
	getErrorPath,
	getUserId,
	getSelectedTheme,
} from '../../redux/slices/userSlice'
import { ampEvent, ampSetUserProperty } from '../../utils/amplitude'
import { paramsToQuery } from '../../utils/paramsToQuery'
import { getSaleScreenABTestValue } from '../../redux/slices/config'

import './styles.scss'
import { flowTypes } from '../../utils/flowTypes'

const ErrorScreen = () => {
	const history = useHistory()
	const email = useSelector(getEmail)
	const isSendConfirmEmail = useSelector(getIsSendConfirmEmail)
	const error = useSelector(getError)
	const flow = useSelector(getFlow)
	const discount = useSelector(getDiscount)
	const errorPath = useSelector(getErrorPath)
	const user_id = useSelector(getUserId)
	const saleScreenABTestValue = useSelector(getSaleScreenABTestValue)
	const selectedTheme = useSelector(getSelectedTheme)
	// const unsubPath = useSelector(getUnsubscribePath)

	const buttonHandler = () => {
		if (errorPath === '/unsubscribe/offer') {
			history.push({
				pathname: `/unsubscribe/offer`,
				search: paramsToQuery({ user_id, email, modal: true }),
			})
		} else if (discount !== '/discount') {
			if (flow === flowTypes.THEMES) {
				history.push({
					pathname: `/sale${flow}`,
					search: paramsToQuery({
						user_id,
						email,
						salescreen_test: saleScreenABTestValue,
						modal: true,
						message: isSendConfirmEmail,
						theme: selectedTheme,
					}),
				})
			} else {
				history.push({
					pathname: `/sale${flow}`,
					search: paramsToQuery({
						user_id,
						email,
						salescreen_test: saleScreenABTestValue,
						modal: true,
						message: isSendConfirmEmail,
					}),
				})
			}
		} else {
			history.push({
				pathname: discount,
				search: paramsToQuery({ user_id, email, modal: true }),
			})
		}
	}

	React.useEffect(() => {
		setTimeout(() => {
			ampSetUserProperty('error', error)
			ampEvent('Error_screen')
		}, 0)
	}, [])

	return (
		<div className="error">
			<img
				src="/images/error/error.png"
				alt="Something went wrong ."
				className="error-image"
			/>
			<h2 className="error-title">Ooops</h2>
			<span className="error-label">
				Sorry, your transaction failed:
				<br />
				{error}
			</span>
			<Button buttonText="Try Again" onClick={buttonHandler} />
		</div>
	)
}

export { ErrorScreen }
