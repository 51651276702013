import React from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { CircularProgressbar } from 'react-circular-progressbar'

import { getFlow, getUserId } from '../../../redux/slices/userSlice'
import { flowTypes } from '../../../utils/flowTypes'

import 'react-circular-progressbar/dist/styles.css'
import './styles.scss'
import { paramsToQuery } from '../../../utils/paramsToQuery'

const CustomizeHomeProgressbar = () => {
	const history = useHistory()
	const flow = useSelector(getFlow)
	const user_id = useSelector(getUserId)

	const [progress, setProgress] = React.useState(0)

	React.useEffect(() => {
		if (progress <= 99) {
			setTimeout(() => {
				setProgress(progress + 1)
			}, 100)
			return
		}

		setTimeout(
			() =>
				history.push({
					pathname: `/email`,
					search: paramsToQuery({ user_id }),
				}),
			2000,
		)
	}, [progress])

	const isCreated = progress > 99

	return (
		<div className="customize-home-progressbar">
			<CircularProgressbar
				maxValue={100}
				minValue={0}
				value={progress - 1}
				strokeWidth={6}
				text={<tspan>{!isCreated ? progress + '%' : '✓'}</tspan>}
				className={isCreated ? 'done' : ''}
			/>
			<div className="customize-home-progressbar__text">
				<h2 className="customize-home-progressbar__text__title">
					Process of your
					{flow === flowTypes.KEYBOARD
						? ' keyboard '
						: flow === flowTypes.THEMES
						? ' theme '
						: ' home screen '}
					customisation...
				</h2>
				<span className="customize-home-progressbar__text__label">
					Complete your {flow === flowTypes.THEMES ? ' themes ' : ' widgets '}{' '}
					pack fully
				</span>
			</div>
		</div>
	)
}

export { CustomizeHomeProgressbar }
