import {
	setBill,
	setError,
	setPayPalToken,
	setCardToken,
	setDiscount,
	setErrorPath,
} from '../../../../../redux/slices/userSlice'

export function payPalHandler(
	pathname,
	history,
	dispatch,
	total,
	lifetime,
	productId,
) {
	return function (e) {
		if (
			e?.detail?.data?.order?.status === 'approved' ||
			e?.detail?.data?.order?.status === 'void_ok'
		) {
			dispatch(
				setBill({
					type: 'PAYPAL',
					value: total,
					lifetime: lifetime,
					product_id: productId,
				}),
			)
			dispatch(setPayPalToken({ payPalToken: e?.detail?.data?.order?.token }))
			pathname === '/unsubscribe/offer'
				? history.push('/success')
				: history.push('/upsale1')
			document.body.style.overflow = 'visible'
		}

		if (
			(e?.type === 'order-processed' &&
				e?.detail?.data?.order?.status === 'declined') ||
			e?.detail?.data?.order?.status === 'auth_failed'
		) {
			dispatch(
				setError({
					error: e.detail?.data?.error?.recommended_message_for_user,
				}),
			)
			dispatch(setDiscount({ discount: pathname }))
			dispatch(setErrorPath({ errorPath: pathname }))
			history.push('/error')
			document.body.style.overflow = 'visible'
		}
	}
}

export function cardHandler(
	pathname,
	history,
	dispatch,
	total,
	lifetime,
	productId,
) {
	return function (e) {
		if (
			e?.data?.response?.order?.status === 'approved' ||
			e?.data?.response?.order?.status === 'void_ok'
		) {
			dispatch(
				setBill({
					type: 'CARD',
					value: total,
					lifetime: lifetime,
					product_id: productId,
				}),
			)
			dispatch(
				setCardToken({
					cardToken:
						e.data.response?.transactions[
							Object.keys(e.data?.response?.transactions)[0]
						]?.card?.card_token?.token ||
						e.data?.response?.transactions[
							Object.keys(e.data?.response?.transactions)[1]
						]?.card?.card_token?.token,
				}),
			)
			pathname === '/unsubscribe/offer'
				? history.push('/success')
				: history.push('/upsale1')
			document.body.style.overflow = 'visible'
		}

		if (
			e?.data?.type === 'orderStatus' &&
			(e?.data?.response?.order?.status === 'declined' ||
				e?.data?.response?.order?.status === 'auth_failed')
		) {
			dispatch(
				setError({
					error: e?.data?.response?.error?.recommended_message_for_user,
				}),
			)
			dispatch(setDiscount({ discount: pathname }))
			dispatch(setErrorPath({ errorPath: pathname }))
			history.push('/error')
			document.body.style.overflow = 'visible'
		}
	}
}
